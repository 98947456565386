import { AxiosError } from 'axios'
import { ServerError } from 'types/common'
import { LearningModuleSummary, ModuleFiles } from 'types/scorm'
import { errorHandler } from './errorHandler'
import { Api } from './http'

export const useModuleService = () => {
	async function completeScormOffline(moduleId: number, payload: { name: string; completetion_message: string }) {
		return Api.post(`/v2/module/module/${moduleId}/complete_offline/`, { ...payload, module_type: 'course' })
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}
	async function getModuleInformation() {
		return Api.get(`/v1/module/webmodule/`)
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	async function getModuleSummary(): Promise<LearningModuleSummary[]> {
		return Api.get(`/v1/module/webmodule/summary/`)
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	async function getModuleInformationByID(id: number) {
		return Api.get(`/v1/module/webmodule/${id}/`)
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	async function getModuleCompletedStatus(moduleNumber: number, chapterNumber: number) {
		return Api.get(`/v1/module/webslidecompleted/${moduleNumber}/${chapterNumber}/is_completed/`)
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	async function completeSlide(moduleNumber: number, chapterNumber: number) {
		return Api.post(`/v1/module/webslidecompleted/${moduleNumber}/${chapterNumber}/`, {})
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	async function completeOffline(moduleNumber: number, chapterNumber: number, payload: { slides: number; complete: number }) {
		return Api.post(`/v1/module/webslidecompleted/${moduleNumber}/${chapterNumber}/complete_offline/`, payload)
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	const modulesDetails = (moduleName: string) => {
		const moduleInfo = [
			{
				name: 'money',
				link: 'http://money.com',
				chapters: [
					{
						name: 'Chapter 1',
						slides: 28,
						completed: 0,
						chapterNumber: 1,
					},
					{
						name: 'Chapter 2',
						slides: 48,
						completed: 0,
						chapterNumber: 2,
					},
					{
						name: 'Chapter 3',
						slides: 41,
						completed: 0,
						chapterNumber: 3,
					},
				],
			},
			{
				name: 'entrepreneurial',
				link: 'http://money.com',
				chapters: [
					{
						name: 'Chapter 1',
						slides: 49,
						completed: 1,
						chapterNumber: 1,
					},
					{
						name: 'Chapter 2',
						slides: 33,
						completed: 1,
						chapterNumber: 2,
					},
					{
						name: 'Chapter 3',
						slides: 33,
						completed: 1,
						chapterNumber: 3,
					},
				],
			},
			{
				name: 'work',
				link: 'http://money.com',
				chapters: [
					{
						name: 'Chapter 1',
						slides: 56,
						completed: 1,
						chapterNumber: 1,
					},
					{
						name: 'Chapter 2',
						slides: 29,
						completed: 1,
						chapterNumber: 2,
					},
					{
						name: 'Chapter 3',
						slides: 52,
						completed: 1,
						chapterNumber: 3,
					},
					{
						name: 'Chapter 4',
						slides: 48,
						completed: 1,
						chapterNumber: 4,
					},
				],
			},
			{
				name: 'people',
				link: 'http://money.com',
				chapters: [
					{
						name: 'Chapter 1',
						slides: 38,
						completed: 1,
						chapterNumber: 1,
					},
					{
						name: 'Chapter 2',
						slides: 28,
						completed: 1,
						chapterNumber: 2,
					},
					{
						name: 'Chapter 3',
						slides: 47,
						completed: 1,
						chapterNumber: 3,
					},
				],
			},
		]
		return {
			getChapter: (chapterNumber: number) => {
				return moduleInfo.find((x) => x.name === moduleName)?.chapters.find((y) => y.name === `Chapter ${chapterNumber}`)
			},
			getModuleChapters: () => {
				return moduleInfo.find((x) => x.name === moduleName)?.chapters
			},
		}
	}

	const moduleAssets: ModuleFiles[] = [
		{
			name: 'money',
			moduleGuide: '67508 RTW FACGUIDE ONLINE MONEY SKILLS.pdf',
			files: ['66540 RTW MOD3 Topic1 Final.pdf', '66541 RTW MOD3 Topic2 Final.pdf', '66542 RTW MOD3 Topic3 Final.pdf'],
			assessment: 'RTW Assessment MOD3 money.pdf',
			answer: 'RTW ANSWER MOD3 money_R1.pdf',
		},
		{
			name: 'entrepreneurial',
			moduleGuide: 'FGUIDE_ENTREPRENEURIAL.pdf',
			files: ['66544 RTW MOD4 Topic1 Final.pdf', '66545 RTW MOD4 Topic2 Final.pdf', '66546 RTW MOD4 Topic3 Final.pdf'],
			assessment: 'RTW Assessment MOD4 entrepreneurial.pdf',
			answer: 'RTW ANSWER MOD4 entrpreneurial_R1.pdf',
		},
		{
			name: 'work',
			moduleGuide: '66534 F_Guide MOD1 work skills FINAL.pdf',
			files: [
				'66457 RTW MOD1 Topic1 FINAL.pdf',
				'66458 RTW MOD1 Topic2 FINAL.pdf',
				'66518 RTW MOD1 Topic3 FINAL.pdf',
				'66519 RTW MOD1 Topic4 FINAL.pdf',
			],
			assessment: 'RTW Assessment MOD1 work.pdf',
			answer: 'RTW ANSWER MOD1 work_R1.pdf',
		},
		{
			name: 'people',
			moduleGuide: '66538 FACGuideMOD2 People skills FINAL.pdf',
			files: ['66524 RTW MOD2 Topic1 Final.pdf', '66527 RTW MOD2 Topic2 Final.pdf', '66523 RTW MOD2 Topic3 Final.pdf'],
			assessment: 'RTW Assessment MOD2 people.pdf',
			answer: 'RTW ANSWER MOD2 people_R1.pdf',
		},
	]

	return {
		getModuleInformation,
		getModuleCompletedStatus,
		completeSlide,
		getModuleInformationByID,
		modulesDetails,
		completeOffline,
		completeScormOffline,
		moduleAssets,
		getModuleSummary,
	}
}
