import { Guest } from 'components/views/single/cfe/guest'
import { Member } from 'components/views/single/cfe/member'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { userCurrentStore } from 'store/userCurrentStore'

export default function PageHomeConsumerEducation() {
	const { currentUser } = userCurrentStore()
	const navigate = useNavigate()

	useEffect(() => {
		if (currentUser && !currentUser.user_type_raw.includes('cfe')) {
			navigate('/account/update-details')
		}
	}, [])

	return currentUser === null ? <Guest /> : <Member />
}
