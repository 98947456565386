import PageAccountResetPassword from 'pages/account/reset-password'
import PageAccountSetResetPassword from 'pages/account/set-reset-password'
import PageHomeConsumerEducation from 'pages/home/consumer-financial-education'
import PageHomeReadyToWork from 'pages/home/ready-to-work'
import React, { memo, useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { useAuthService } from 'services/user'
import RoutesErrorBoundary from '../components/layout/routes-error-boundary'
import Routes from '../components/layout/routes-layout'
import { TOKEN_TAG } from '../constants'
import PageAccountHome from '../pages/account/home'
import PageAccountUpdateContactNumber from '../pages/account/update-contact-number'
import PageAccountUpdateDetails from '../pages/account/update-details'
import PageAccountUpdateEmail from '../pages/account/update-email'
import PageAccountUpdatePassword from '../pages/account/update-password'
import PageAccountUpdateProfile from '../pages/account/update-profile'
import PageLoginConfirmDetails from '../pages/auth/login/confirm-details'
import { default as PageLoginCredentials } from '../pages/auth/login/credentials'
import PageLoginValidate from '../pages/auth/login/validate'
import PageLogout from '../pages/auth/logout/logout'
import PageRegisterCredentials from '../pages/auth/register/credentials'
import PageRegisterInformation from '../pages/auth/register/information'
import PageValidate from '../pages/auth/validate/validate'
import PageFaqs from '../pages/faqs/faqs'
import PageHome from '../pages/home/home'
import PageNotFound from '../pages/not-found/404'
import PageResources from '../pages/resources/resources'
import { RouteProps } from '../types/common'
import { handleGuestOnly, handleProtected } from '../utils'

export const routes: RouteProps[] = [
	{
		index: true,
		label: 'Dashboard',
		icon: 'home',
		element: <PageHome />,
		placement: ['aside'],
		display: ['public', 'private'],
	},
	{
		index: true,
		path: 'consumer-financial-education',
		label: 'Consumer Financial Education',
		icon: 'consumerEducation',
		element: <PageHomeConsumerEducation />,
		placement: ['aside', 'header-left'],
		display: ['public', 'private'],
		// children: [
		// 	{
		// 		index: true,
		// 		element: <PageHomeConsumerEducation />,
		// 	},
		// 	{
		// 		path: 'flc',
		// 		label: 'Financial Literacy Course',
		// 		icon: 'add',
		// 		// element: <ScormModuleViewPage />,
		// 		element: <ScormAssessment />,
		// 		loader: async () => await handleProtected(),
		// 	},
		// ],
	},
	{
		index: true,
		path: 'ready-to-work',
		label: 'ReadyToWork',
		icon: 'readyToWork',
		element: <PageHomeReadyToWork />,
		placement: ['aside', 'header-left'],
		display: ['public', 'private'],
	},
	{
		index: true,
		path: 'faqs',
		label: 'FAQs',
		icon: 'faqs',
		element: <PageFaqs />,
		placement: ['aside', 'header-left'],
		display: ['public', 'private'],
	},
	{
		index: true,
		path: 'resources',
		label: 'Resources',
		icon: 'resources',
		divider: [`bottom`],
		element: <PageResources />,
		placement: ['aside', 'header-left'],
		loader: async () => await handleProtected(),
		display: ['public', 'private'],
	},
	{
		path: 'account',
		label: 'Profile',
		icon: 'profile',
		placement: ['aside', 'header-right'],
		loader: async () => await handleProtected(),
		display: ['private'],
		children: [
			{
				index: true,
				element: <PageAccountHome />,
			},
			{
				path: 'update',
				label: 'Update your user account',
				icon: 'add',
				element: <PageAccountUpdateProfile />,
			},
			{
				path: 'update-email',
				label: 'Update your email address',
				icon: 'add',
				element: <PageAccountUpdateEmail />,
			},
			{
				path: 'update-contact-number',
				label: 'Update your contact number',
				icon: 'add',
				element: <PageAccountUpdateContactNumber />,
			},
			{
				path: 'update-password',
				label: 'Update your password',
				icon: 'add',
				element: <PageAccountUpdatePassword />,
			},
			{
				path: 'update-details',
				label: 'Update your details',
				icon: 'login',
				element: <PageAccountUpdateDetails />,
			},
		] as RouteProps[],
	},
	{
		path: 'login',
		label: 'Login',
		icon: 'login',
		placement: ['aside', 'header-right'],
		loader: async () => await handleGuestOnly(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageLoginCredentials />,
			},
			{
				path: 'validate',
				label: 'Validate login',
				icon: 'login',
				element: <PageLoginValidate />,
			},
			{
				path: 'confirm-details',
				label: 'Confirm your details',
				icon: 'login',
				private: true,
				element: <PageLoginConfirmDetails />,
			},
		] as RouteProps[],
	},
	{
		path: 'reset-password',
		label: 'Reset Password',
		icon: 'login',
		placement: ['aside', 'header-right'],
		loader: async () => await handleGuestOnly(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageAccountResetPassword />,
			},
			{
				path: 'set',
				label: 'New Password',
				icon: 'login',
				element: <PageAccountSetResetPassword />,
			},
			// {
			// 	path: 'validate',
			// 	label: 'Validate login',
			// 	icon: 'login',
			// 	element: <PageLoginValidate />,
			// },
			// {
			// 	path: 'confirm-details',
			// 	label: 'Confirm your details',
			// 	icon: 'login',
			// 	private: true,
			// 	element: <PageLoginConfirmDetails />,
			// },
		] as RouteProps[],
	},
	{
		path: 'register',
		label: 'Create an account',
		icon: 'add',
		placement: ['aside'],
		loader: async () => await handleGuestOnly(),
		display: ['public'],
		children: [
			{
				index: true,
				element: <PageRegisterCredentials />,
			},
			{
				path: 'information',
				label: 'Provide your profile information',
				icon: 'add',
				element: <PageRegisterInformation />,
			},
		] as RouteProps[],
	},
	{
		index: true,
		path: 'validate',
		label: 'Validate',
		icon: 'add',
		element: <PageValidate />,
	},
	{
		index: true,
		path: 'logout',
		label: 'Log out',
		icon: 'logout',
		placement: ['aside'],
		display: ['private'],
		element: <PageLogout />,
	},
	{
		path: '*',
		element: <PageNotFound />,
	},
] as RouteProps[]

export const layout: RouteProps[] = [
	{
		path: '/',
		element: <Routes />,
		errorElement: <RoutesErrorBoundary />,
		children: routes,
	},
]

const router = createBrowserRouter(layout, {
	future: {
		v7_normalizeFormMethod: true,
	},
})

export const AppNavigator: React.FC = memo(() => {
	const { userLogout } = useAuthService()
	const [checkSession, setCheckSession] = React.useState<number>()

	useEffect(() => {
		const sessionCheckerFn: number = window.setInterval(() => {
			const tokenStr = window.localStorage.getItem(TOKEN_TAG)
			if (tokenStr) {
				const token = JSON.parse(tokenStr)
				const createdAt = new Date(Date.parse(token['created_at']))
				const now = new Date()
				const deltaTime = now.getTime() - createdAt.getTime()
				const expireAfterSeconds = 60 * 59 * 2 * 1000
				if (deltaTime > expireAfterSeconds) {
					userLogout().then(() => {
						window.location.pathname = '/login'
					})
				}
			}
		}, 1000)
		setCheckSession(sessionCheckerFn)

		return () => {
			clearInterval(checkSession)
		}
	}, [])

	return <RouterProvider router={router} />
})
