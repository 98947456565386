import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { clsx } from 'clsx'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { colors, sizing } from '../../../constants'
import { useRoutes } from '../../../hooks/useRoutes'
import { userCurrentStore } from '../../../store/userCurrentStore'
import { RouteProps } from '../../../types/common'
import { Icon } from '../../icon'

interface ComponentProps {
	toggleDrawer?: () => void
}

const Component: React.FC<ComponentProps> = (props) => {
	const { toggleDrawer } = props
	const { routes } = useRoutes()
	const { currentUser } = userCurrentStore()

	function showAll(display: any, pv: string, pb: string) {
		return display?.includes(pv) && display?.includes(pb)
	}

	return (
		<Box
			className={`-sidebar-main`}
			sx={{
				overflowY: 'auto',
			}}>
			<Box sx={{ width: `100%` }}>
				<List>
					{routes
						.filter(({ placement }: RouteProps) => placement?.includes('aside'))
						.filter(({ display }: RouteProps) =>
							currentUser
								? display?.includes(`private`) || showAll(display, `private`, `public`)
								: display?.includes(`public`)
						)
						.map(({ label, icon, divider = [], focus, path }: RouteProps, i: number) => {
							return (
								<Link
									to={path ?? `/`}
									onClick={toggleDrawer}
									target={path?.includes(`http`) ? `_blank` : `_self`}
									rel={`noreferrer`}
									key={i + 1}
									className={clsx(`-sidebar-main-item`, focus && `-focused`)}>
									<ListItem
										sx={{
											backgroundColor: focus ? colors.primary : colors.bgSurface,
										}}>
										<ListItemButton onClick={() => {}}>
											{icon && (
												<ListItemIcon sx={{ minWidth: 38 }}>
													<Icon name={icon} width={`20px`} height={`20px`} color={colors.text} />
												</ListItemIcon>
											)}
											<ListItemText
												primary={label}
												primaryTypographyProps={{
													fontSize: Math.round(sizing.bodyFontSize * 1.15),
													color: focus ? colors.bgSurface : colors.text,
													paddingLeft: `${sizing.themeSpacing}px`,
													className: clsx(`-cdp-aside-menu-item`, focus && 'focused'),
												}}
											/>
										</ListItemButton>
									</ListItem>
									{divider?.includes(`bottom`) && !focus && <Divider />}
								</Link>
							)
						})}
				</List>
			</Box>
		</Box>
	)
}

export default memo(Component)
