import { Box, Card, CardContent, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Button } from 'components/button/button'
import { Column, Row } from 'components/grid'
import { HeroHome } from 'components/hero'
import { Illustration } from 'components/illustration'
import { Spacer } from 'components/layout/spacer'
import { useNavigate } from 'react-router-dom'
import { colors } from '../../../../../../constants'

export default function Component() {
	const navigate = useNavigate()
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const direction = isMobile ? `row` : `row-reverse`
	return (
		<>
			<HeroHome />
			<Container maxWidth={`lg`}>
				<Box padding={theme.spacing(3)}>
					<Grid container={true} columnSpacing={{ md: 4 }} rowSpacing={{ sm: 3 }}>
						<Grid item={true} xs={12}>
							<Card style={{ borderBottom: `4px solid ${colors.brand_care}` }}>
								<CardContent>
									<Column>
										<Grid container={true} sx={{ flexDirection: direction }}>
											<Grid item={true} xs={12} md={6}>
												<Row justifyContent={`center`}>
													<Box maxWidth={`xs`} textAlign={`center`}>
														<Illustration name={`sittingBehindDesk`} width={300} />
													</Box>
												</Row>
											</Grid>
											<Grid item={true} xs={12} md={6}>
												<Spacer height={theme.spacing(3)} />
												<Typography variant={`h4`} fontWeight={700}>
													Consumer Financial Education
												</Typography>
												<Typography variant={`normal`}>
													Consumer Financial Education is a financial literacy initiative designed to help our
													users expand their knowledge on money matters and understanding their finances better.
													Create an account or login to access our online course for free.
												</Typography>
												<Spacer height={theme.spacing(3)} />
												<Button
													variant={`outlined`}
													color={`secondary`}
													fullWidth={isMobile}
													onClick={() => {
														navigate(`/consumer-financial-education`)
													}}>
													Learn More
												</Button>
											</Grid>
										</Grid>
									</Column>
									<Spacer height={theme.spacing(3)} />
								</CardContent>
							</Card>
							<Spacer height={theme.spacing(4)} />
							<Card style={{ borderBottom: `4px solid ${colors.brand_care}` }}>
								<CardContent>
									<Grid container={true} sx={{ flexDirection: direction }}>
										<Grid item={true} xs={12} md={6}>
											<Row justifyContent={`center`}>
												<Illustration name={`graduationOpenDoor`} width={300} />
											</Row>
										</Grid>
										<Grid item={true} xs={12} md={6}>
											<Spacer height={theme.spacing(3)} />
											<Typography variant={`h4`} fontWeight={700}>
												Absa ReadytoWork
											</Typography>
											<Typography variant={`normal`}>
												Yesterday's skill won't cut it in tomorrow's world of work. Become future-ready with the
												emotional and digital skills you need to get ahead in the game with Absa ReadytoWork.
											</Typography>
											<Spacer height={theme.spacing(3)} />

											<Button
												variant={`outlined`}
												color={`secondary`}
												fullWidth={isMobile}
												onClick={() => navigate('/ready-to-work')}>
												Learn More
											</Button>
										</Grid>
									</Grid>
									<Spacer height={theme.spacing(4)} />
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</>
	)
}
