import { IScormService } from 'services/scorm'
import { User } from '../types/user'
import errorDefinition from './scorm_errors'
import Storage from './scorm_storage'

export const completeStatusKey = 'cmi.core.lesson_status'

export default class SCORMAdapter {
	moduleName: string
	user: User
	scormService: IScormService
	api: string
	backToModuleList?: (commited?: boolean) => void

	constructor(moduleName: string, user: User, scormService: IScormService, backToModuleList?: (commited?: boolean) => void) {
		this.moduleName = moduleName
		this.user = user
		this.backToModuleList = backToModuleList
		this.scormService = scormService
	}

	LMSInitialize() {
		Storage.setItem('Initialized', true)
		return true
	}

	async LMSFinish() {
		if (!this._isInitialized()) {
			this._setError(301)
			return false
		}
		let _return = await this.LMSCommit()
		Storage.setItem('Initialized', false)
		Storage.clearAll()

		if (this.backToModuleList != null) {
			this.backToModuleList(true)
		}

		return _return
	}

	LMSGetValue(element: string) {
		if (!this._isInitialized()) {
			this._setError(301)
			return false
		}
		let value = Storage.getItem(element)

		if (value === null) {
			this.scormService
				.getValue(element, this.moduleName)
				.then((response: any) => {
					value = response
				})
				.catch((error: any) => {
					console.log(error)
				})
		}

		if (value === null) {
			this._setError(201)
			return ''
		}
		return value
	}

	LMSSetValue(element: string, value: any) {
		if (!this._isInitialized()) {
			this._setError(301)
			return false
		}
		Storage.setItem(element, value)
		return true
	}

	async LMSCommit() {
		let data: any = Storage.getAll()
		delete data['errors']
		delete data['Initialized']
		let commitData = data

		this.scormService
			.commit(commitData, this.moduleName)
			.then((response: any) => {
				if (!response.success) {
					console.log('SCORM Commit Failed.')
					this._setError(101)
					return false
				}
			})
			.catch((error: any) => {
				console.log(error)
			})

		return true
	}

	LMSGetLastError() {
		let errors = Storage.getItem('errors')
		errors = JSON.parse(errors)
		if (errors && errors.length > 0) {
			return errors.pop()
		}
		return 0
	}

	LMSGetErrorString(errorCode: any) {
		errorCode = errorCode.toString()
		let error = errorDefinition[errorCode]
		if (!error) return ''
		return error['errorString']
	}

	LMSGetDiagnostic(errorCode: any) {
		errorCode = errorCode.toString()
		let error = errorDefinition[errorCode]
		if (!error) return ''
		return error['diagnostic']
	}

	_isInitialized() {
		let initialized = Storage.getItem('Initialized')
		return initialized
	}

	_setError(errorCode: any) {
		errorCode = errorCode.toString()
		let errors = Storage.getItem('errors')
		if (!errors) errors = '[]'
		errors = JSON.parse(errors)
		errors.push(errorCode)
		errors = JSON.stringify(errors)
		Storage.setItem('errors', errors)
	}
}
