import { LinearProgress as MuiLinearProgress } from '@mui/material'
import React from 'react'

interface GlobalProgressInterface {
	height?: number
	isLoading?: boolean
}

const GlobalProgress: React.FC<GlobalProgressInterface> = ({ height = 3, isLoading = false }) => {
	return isLoading ? (
		<MuiLinearProgress
			color={'secondary'}
			sx={{
				zIndex: 999999,
				height,
				position: 'fixed',
				left: 0,
				top: 0,
				right: 0,
			}}
		/>
	) : null
}

export default GlobalProgress
