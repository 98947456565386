import { FormControl, FormHelperText } from '@mui/material'
import clsx from 'clsx'
import { getIn, useFormikContext } from 'formik'
import React, { ReactNode, memo, useEffect, useState } from 'react'
import { sizing } from '../../../../constants'
import { errorMessages } from '../../../../message/errorMessages'
import { parseInputHelper } from '../../../../utils'
import { Box } from '../../../box/box'
import { Link } from '../../../link'
import { SkeletonInput } from '../../../skeleton/skeleton-input'
import { Label } from '../../label'

interface InputFieldWrapperProps {
	name: string
	label?: React.ReactNode | string
	hint?: React.ReactNode | string
	children?: ReactNode
	errorOccurred?: ReactNode
	center?: boolean
	className?: string
	plain?: boolean
}

export const requireLoginErrorChecker = (errorText: string): boolean => {
	return errorText.includes(errorMessages.user.EMAIL_EXISTS) || errorText.startsWith(errorMessages.user.CONTACT_NUMBER_EXISTS)
}

const Component: React.FC<InputFieldWrapperProps> = ({ name, label, hint, children, errorOccurred, center, className, plain }) => {
	const [isLoading, setIsLoading] = useState(true)
	const { errors, touched } = useFormikContext()
	const error = getIn(errors, name)
	const touch = getIn(touched, name)
	const hasError = !!errorOccurred || (touch && error)
	const errorText = errorOccurred ?? parseInputHelper(touch && error ? error : ``)

	const paddingTop = sizing.inputHeight * 0.5
	const paddingBottom = sizing.themeSpacing / 2
	const minHeight = sizing.inputHeight + paddingTop + paddingBottom
	const align = center ? `center` : `left`

	useEffect(() => {
		setIsLoading(false)
	}, [])

	return (
		<Box
			className={`-absa-cdp-ce-input`}
			paddingTop={`${plain ? 0 : paddingTop}px`}
			paddingBottom={`${paddingBottom}px`}
			minHeight={`${plain ? sizing.inputHeight : minHeight}px`}
			textAlign={align}>
			<FormControl
				fullWidth={true}
				error={hasError}
				className={clsx(`-cdp-input-form-control`, className, hasError && `-input-error`)}>
				{label ? <Label name={name} label={label} isLoading={isLoading} /> : null}
				{isLoading ? (
					<SkeletonInput />
				) : (
					<React.Fragment>
						{children && (
							<Box fullWidth={!center} minHeight={sizing.inputHeight} textAlign={align} className={`-cdp-input-field`}>
								<Box fullWidth={!center} margin={`0 auto`} textAlign={align}>
									{children}
								</Box>
							</Box>
						)}
					</React.Fragment>
				)}

				{errorText && !isLoading && (
					<Box className={`-cdp-input-hint`} textAlign={align}>
						<FormHelperText error={true} sx={{ textAlign: align }}>
							{`${errorText} `}
							{requireLoginErrorChecker(errorText) && (
								<Link text={` Go to login`} to={`/login`} bold={true} underline={true} />
							)}
						</FormHelperText>
					</Box>
				)}
				{hint && !isLoading && (
					<Box className={`-cdp-input-hint`} textAlign={align}>
						{typeof hint === 'string' ? <FormHelperText sx={{ textAlign: align }}>{hint}</FormHelperText> : hint}
					</Box>
				)}
			</FormControl>
		</Box>
	)
}

export default memo(Component)
