import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { STORE } from '../constants'
import { User } from '../types/user'

export interface Store {
	currentUser: NonNullable<User | null>
	setCurrentUser: (_data: Partial<User>) => void
	removeCurrentUser: () => void
}

export const initialState: Store = {
	// @ts-ignore
	currentUser: null,
	setCurrentUser: () => {},
	removeCurrentUser: () => {},
}

export const userCurrentStore = create<Store>()(
	devtools(
		persist(
			(set) => ({
				...initialState,
				setCurrentUser: (data: any) => {
					return set(() => ({
						currentUser: data,
					}))
				},
				removeCurrentUser: () => {
					return set(() => ({
						currentUser: null,
					}))
				},
			}),
			{
				name: STORE.USER_CURRENT,
				getStorage: () => localStorage,
			}
		)
	)
)
