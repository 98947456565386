import { permittedDialingCodes } from 'constants/options'
import dayjs from 'dayjs'
import { scormModuleNames } from 'helpers/scorm_storage'
import { errorMessages } from 'message/errorMessages'
import { redirect } from 'react-router-dom'
import { AnyObject, StringSchema, ValidationError } from 'yup'
import { TOKEN_TAG } from '../constants'
import { AddressInterface } from '../types/common'
import { UserTokenType } from '../types/user'

export const isObject = (obj: any) => {
	return obj !== undefined && obj !== null && obj.constructor === Object
}

export const getFirstPropertyValue = (obj: any) => {
	const keys = Object.keys(obj)
	const firstKey = keys[0]
	const firstValue = obj[firstKey]
	return firstValue
}

export const isDormantAccount = (date: string) => dayjs(date).isBefore(dayjs().subtract(6, 'months'))

export const isRtwUserOnly = (user_type: string) => (!user_type.includes('cfe') && user_type.includes('rtw') ? true : false)

export const parseInputHelper = (h: any) => {
	return !h ? '' : typeof h === 'string' ? h : isObject(h) ? getFirstPropertyValue(h) : h
}

export const sleep = (ms: any) => {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getToken = (): UserTokenType | null => {
	try {
		const tokenStore = window.localStorage.getItem(TOKEN_TAG)
		const token = tokenStore ? JSON.parse(tokenStore) : ({} as UserTokenType)
		return token?.access ? token : ({} as UserTokenType)
	} catch {
		return {} as UserTokenType
	}
}

export const maskEmail = (email: string): string => {
	const emailParts = email.split('@')
	const maskedEmail = `${emailParts[0].charAt(0)}****@${emailParts[1]}`
	return maskedEmail
}

export const maskContactNumber = (contact_number: string): string => {
	const length = contact_number.length
	return contact_number.slice(0, length - 4).replace(/\d/g, '*') + contact_number.slice(length - 4)
}

export const getGooglePlace = (place: any): AddressInterface => {
	let country, state, municipality, town

	try {
		country = place?.addressComponents?.find((o: any) => o.types.includes('country'))
		state = place?.addressComponents?.find((o: any) => o.types.includes('administrative_area_level_1'))
		municipality = place?.addressComponents?.find((o: any) => o.types.includes('administrative_area_level_2'))
		town = place?.addressComponents?.find((o: any) => o.types.includes('locality'))
	} catch (error) {
		// @ts-ignore
	}

	const address = place?.formattedAddress
	const location = place?.location

	return {
		address,
		...(country && { country: country.shortText }),
		...(country && { countryName: country.longText }),
		...(state && { state: state.shortText }),
		...(state && { stateName: state.longText }),
		...(town && { town: town.longText }),
		...(municipality && { municipality: municipality.shortText }),
		location,
	}
}

export const handleProtected = async (): Promise<any> => {
	const token = localStorage.getItem(TOKEN_TAG)
	if (!token) throw redirect('/login')
	return null
}

export const handleGuestOnly = async (): Promise<any> => {
	const token = localStorage.getItem(TOKEN_TAG)
	if (token) throw redirect('/')
	return null
}

export const obfusticateEmail = (email: string): string => {
	if (email.length === 0) {
		return ''
	}

	const [userInfo, domain] = email.split('@')

	const obfusticateValue = (value: string, hideBeginning: boolean): string => {
		if (value.length < 2) {
			return value
		}

		if (value.length < 3) {
			if (hideBeginning) {
				return `*${value[1]}`
			}
			return `${value[0]}*`
		}

		const startCount = value.length - 2
		if (hideBeginning) {
			return `${'*'.repeat(startCount)}${value.substring(value.length - 3, value.length)}`
		}
		return `${value.substring(0, 2)}${'*'.repeat(startCount)}`
	}

	const newUserInfo = userInfo
		.split('.')
		.map((value) => obfusticateValue(value, false))
		.join('.')

	const domainParts = domain.split('.')
	domainParts[0] = obfusticateValue(domainParts[0], true)
	const newDomain = domainParts.join('.')

	return `${newUserInfo}@${newDomain}`
}

export const createContactNumberValidator = (fieldName: string, schema: StringSchema<string, AnyObject, undefined, ''>) => {
	return schema
		.required(errorMessages.user.CONTACT_NUMBER)
		.test('contact-number-validation', errorMessages.user.CONTACT_NUMBER_INVALID, (value) => {
			const parts = value.split(' ')
			const possibleDialingCodes = new Set(Object.keys(permittedDialingCodes))
			const dialingCode: string = parts[0]

			if (!possibleDialingCodes.has(dialingCode) || parts.length < 2) {
				throw new ValidationError(errorMessages.user.CONTACT_NUMBER_INVALID, value, fieldName)
			}

			const firstPartOfActualNumber = parts[1]
			if (firstPartOfActualNumber.startsWith('0')) {
				throw new ValidationError(errorMessages.user.CONTACT_NUMBER_LEADING_ZERO, value, fieldName)
			}

			const permittedDialingCode = permittedDialingCodes[dialingCode]
			const regexString = permittedDialingCode.maxLengths
				.map((expectedLength) => `(?:\\b[1-9]{1}[0-9]{${expectedLength - 1}}\\b)`)
				.join('|')
			console.log('regexString:', regexString)
			const regex = new RegExp(regexString)
			const restOfNumber = parts.slice(1).join('')

			if (!regex.test(restOfNumber)) {
				throw new ValidationError(errorMessages.user.CONTACT_NUMBER_INVALID_LENGTH, value, fieldName)
			}

			return true
		})
}

export const formatModuleName = (name: string): string => {
	let formatedName = ''
	const cleanName = name.replaceAll('_', ' ')
	formatedName = `${cleanName[0].toUpperCase()}${cleanName.slice(1)}`
	if (!scormModuleNames.includes(name)) {
		formatedName = `${formatedName} skills`
	}
	return formatedName
}

export const scrollToTop = () => {
	document.body.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth',
	})
}

export const getDialingCode = (country: string): string => {
	const keys = Object.entries(permittedDialingCodes)
	for (const [key, value] of keys) {
		if (value.name === country) {
			return key
		}
	}
	return '+27'
}
