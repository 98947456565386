import { Box, Typography, useMediaQuery } from '@mui/material'
import { Button } from 'components/button/button'
import { Icon } from 'components/icon'
import { colors, sizing } from '../../../../../../constants'

interface AppAssessmentProgressProps {
	title: string
	actionlabel?: string
	onActionClick?: () => void
}

const styles = {
	buttonColor: {
		color: colors.secondary,
		borderColor: colors.brand_grey_1,
	},
}

const ListItem: React.FC<AppAssessmentProgressProps> = ({ title, actionlabel = 'Download', onActionClick }) => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true

	return (
		<Box alignItems='center' display='flex' sx={{ paddingY: `${sizing.themeSpacing}px` }}>
			<Box flexGrow={1}>
				<Typography variant={isMobile ? 'normal' : 'medium'} marginBottom='0px' fontWeight={600}>
					{title}
				</Typography>
			</Box>
			<Box>
				{onActionClick && (
					<Button onClick={onActionClick} color='secondary' sx={styles.buttonColor} size='small' variant='outlined'>
						{' '}
						<Icon name={`download`} width={`14px`} height={`14px`} color={colors.secondary} />{' '}
						<Box marginLeft={1}>{actionlabel}</Box>
					</Button>
				)}
			</Box>
		</Box>
	)
}

export default ListItem
