import { Container } from '@mui/material'
import { FormAuthValidate } from '../../../components/form-controlled/validate/validate'

export default function PageLoginValidate() {
	return (
		<Container maxWidth={`xs`}>
			<FormAuthValidate />
		</Container>
	)
}
