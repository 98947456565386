import { Typography } from '@mui/material'
import * as React from 'react'
import { IoArrowForwardCircleOutline } from 'react-icons/io5'
import { sizing } from '../../../../constants'
import { Alert } from '../../../alert'
import { Box } from '../../../box/box'
import { Row } from '../../../grid'
import { Icon } from '../../../icon'
import { Spacer } from '../../../layout/spacer'
import { CircularProgress } from '../../../progress'

interface FormStatus {
	error?: string
	success?: string
}

interface FormTitleProps {
	progress?: number
	title?: string
	subTitle?: string
	description?: string | React.ReactNode
	nextStep?: string
	status?: FormStatus
}

const Component: React.FC<FormTitleProps> = ({ progress, title, subTitle, description, nextStep, status }) => {
	return (
		<Box>
			{title && (
				<Box fullWidth={true}>
					<Typography variant={`h3`} textAlign={`center`}>
						{title}
					</Typography>
				</Box>
			)}
			{description && (
				<Row textAlign={`center`}>
					<Typography textAlign={`justify`} fontSize={sizing.bodyFontSize * 1.2}>
						{description}
					</Typography>
				</Row>
			)}
			{(progress || subTitle || nextStep) && (
				<React.Fragment>
					<Spacer height={32} />
					<Row alignItems={`center`}>
						{progress && (
							<Box minWidth={64}>
								<CircularProgress
									size={45}
									icon={<Icon name={`formEdit`} width={`20px`} height={`20px`} />}
									value={progress}
								/>
							</Box>
						)}
						<Row flexGrow={1} justifyContent={`flex-start`}>
							{subTitle && (
								<Typography variant={`h5`} fontWeight={800} margin={0} marginRight={`${sizing.themeSpacing * 2}px`}>
									{subTitle}
								</Typography>
							)}
							{nextStep && (
								<Row alignItems={`center`} justifyContent={`start`}>
									<IoArrowForwardCircleOutline size={`${sizing.themeSpacing * 3}px`} />
									<Typography
										sx={{
											marginLeft: `${sizing.themeSpacing / 2}px`,
										}}>
										{nextStep}
									</Typography>
								</Row>
							)}
						</Row>
					</Row>
				</React.Fragment>
			)}

			<Spacer height={sizing.themeSpacing} />
			{status?.error ? (
				<Alert variant={`outlined`} severity={`error`} message={status?.error} />
			) : status?.success ? (
				<Alert variant={`outlined`} severity={`success`} message={status?.success} />
			) : (
				<React.Fragment />
			)}
		</Box>
	)
}

export default Component
