import { Container, Grid, Typography } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Spacer } from 'components/layout/spacer'
import AppCard from 'components/views/single/dashboard/member/src/Component.appCard'
import React from 'react'
import { sizing } from '../../../../../../constants'
import ListItem from './Component.listItem'

export default function Component() {
	const downloadTxtFile = async (filename: string) => {
		const downloadfile = (await import(`../../../../../../assets/resources/${filename}`)).default
		const element = document.createElement('a')
		element.href = downloadfile
		element.download = filename
		element.click()
	}
	return (
		<React.Fragment>
			{/* <Container> */}
			<Container>
				<Spacer height={theme.spacing(3)} />
				<Typography variant={`h2`}>Resources</Typography>
				<Spacer height={theme.spacing(3)} />
				<Grid container spacing={`${sizing.themeSpacing * 4}px`}>
					<Grid item md={6} sm={12} xs={12} display={'flex'} alignItems='stretch' justifyItems='stretch'>
						<AppCard fullWidth title='Consumer Financial Education'>
							<ListItem
								title='Financial Literacy Course'
								onActionClick={() => downloadTxtFile('Financial_literacy_course.pdf')}
							/>
						</AppCard>
					</Grid>
				</Grid>
				<Spacer height={theme.spacing(4)} />
			</Container>
		</React.Fragment>
	)
}
