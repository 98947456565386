import { Box } from '@mui/material'
import * as React from 'react'
import { colors, sizing } from '../../constants'
import { Icon } from '../icon'

interface ButtonAppStoreInterface {
	store: 'apple' | 'huawei' | 'google'
	url: string
}

export const ButtonAppStore: React.FC<ButtonAppStoreInterface> = ({ store, url }) => {
	return (
		<Box
			component={`button`}
			onClick={() => window.open(url, '_blank')}
			width={`100%`}
			borderRadius={`${sizing.borderRadius}px`}
			display={`flex`}
			flexDirection={`row`}
			alignItems={`center`}
			justifyContent={`center`}
			sx={{
				backgroundColor: colors.black,
				height: sizing.inputHeight + 8,
				minHeight: sizing.inputHeight + 8,
				display: `flex`,
				cursor: `pointer`,
			}}>
			<Icon name={store} height={26} width={116} />
		</Box>
	)
}
