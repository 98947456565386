export const __DEV__ = process.env.NODE_ENV === `development`
export const TOKEN_TAG = `__cdp`
export const TOKEN_LOADER_TAG = `___loader`
export const API_URL = process.env.REACT_APP_API_URL ?? ``
export const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY ?? ``
export const DATE_FORMAT = `YYYY-MM-DD`
export const PASSWORD_VALIDATION_RGX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const RTW_URL = process.env.REACT_APP_RTW_URL ?? ``

/**
 * Stores
 */
export const STORE = {
	USER_CURRENT: `store-user-current`,
	USER_REGISTRATION: `store-user-pre-registration`,
}

export { default as colors } from '../assets/material/colors'
export { default as sizing } from '../assets/material/sizing'
