import { useLocation } from 'react-router-dom'
import { routes as navigationRoutes } from '../navigation/navigation'

export const useRoutes = () => {
	const { pathname } = useLocation()
	const getPath = (path: string) => (path.startsWith('/') ? path.substring(1) : path)

	const routes = navigationRoutes.map((item) => ({
		...item,
		focus: getPath(pathname) === getPath(item?.path ?? ``),
	}))

	return {
		routes,
		pathname,
	}
}
