import React from 'react'

import clsx from 'clsx'
import { ReactComponent as GraduationOpenDoor } from '../../../assets/illustration/graduation-open-door.svg'
import { ReactComponent as PhoneCheck } from '../../../assets/illustration/phone-check.svg'
import { ReactComponent as RetailRunning } from '../../../assets/illustration/retail-running.svg'
import { ReactComponent as Retail } from '../../../assets/illustration/retail.svg'
import { ReactComponent as SittingBehindDesk } from '../../../assets/illustration/sitting-behind-desk.svg'
import { ReactComponent as Success } from '../../../assets/illustration/success.svg'

import { IllustrationName } from '../../../types/common'

export const illustrationMap = {
	retail: Retail,
	retailRunning: RetailRunning,
	phoneCheck: PhoneCheck,
	sittingBehindDesk: SittingBehindDesk,
	graduationOpenDoor: GraduationOpenDoor,
	success: Success,
}

export interface IconProps {
	name: IllustrationName
	width?: string | number
	height?: string | number
	color?: string
	className?: string
}

const Component: React.FC<IconProps> = ({ name, width, height, color, className }) => {
	const Illustration = illustrationMap[name]
	return Illustration ? (
		<Illustration width={width} height={height} fill={color} color={color} className={clsx(`-cdp-ce-illustration`, className)} />
	) : null
}

export default Component
