export const errorMessages = {
	user: {
		FIRST_NAME: `Please enter your first name`,
		LAST_NAME: `Please enter your last name`,
		EMAIL: `Please enter your email address`,
		EMAIL_EXISTS: `You can use the same log in details for Consumer Financial Education and ReadytoWork`,
		OTP: `Please enter a valid One-time pin`,
		PASSWORD: `Please provide a valid password`,
		PASSWORD_MATCH: `Passwords must match`,
		PASSWORD_SPEC: `Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character`,
		CONTACT_NUMBER: `Please enter your contact number`,
		CONTACT_NUMBER_EXISTS: `Existing phone number. This phone number is already associated to another profile for Consumer Financial Education, ReadytoWork, and Absa Fellowship.`,
		CONTACT_NUMBER_LEADING_ZERO: `Remove 0 from cellphone`,
		CONTACT_NUMBER_INVALID_LENGTH: `Number has an invalid length`,
		CONTACT_NUMBER_INVALID: `Please enter a valid a valid number`,
		COUNTRY_RESIDENCE: `Please select your country or residency`,
		COUNTRY_OTHER: `To register, you must live in one of the countries in the above list`,
		COUNTRY_CITIZENSHIP: `Please select your country or citizenship`,
		ADDRESS: `Please enter a valid address`,
		LOCATION: `Please enter a valid location`,
		MUNICIPALITY: `Please enter a valid municipality`,
		TOWN: `Please enter a valid town`,
		STATE: `Please enter a valid state`,
		DATE_OF_BIRTH: `You must be over 18 to register`,
		GENDER: `Please select your gender`,
		RACE: `Please select your race`,
		ID_NUMBER: `ID Number must match a valid SA ID Number`,
		INCOME: `Please select your income range`,
		TERMS: `You must agree to our terms and conditions`,
		ACCOUNT_EXISTS: `An account with these credentials exists`,
		ACCOUNT_NOT_EXISTS: `No active account found with the given credentials`,
	},
}
