import { Link as MuiLink, SxProps } from '@mui/material'
import React, { ReactNode } from 'react'
import { Link as RRDLink } from 'react-router-dom'

interface LinkInterface {
	text: ReactNode
	to: string
	target?: string
	rel?: string
	bold?: boolean
	underline?: boolean
	sx?: SxProps
	onClick?: (e: any) => any
}

const Link: React.FC<LinkInterface> = ({ onClick, text, to, target = '_self', rel = 'noreferrer', bold, underline, sx }) => {
	return (
		<RRDLink
			onClick={onClick}
			to={to}
			target={target}
			rel={rel}
			style={{
				display: `inline-block`,
				textDecoration: underline ? `underline` : `none`,
				fontWeight: bold ? 600 : 400,
			}}>
			<MuiLink
				component={`span`}
				align={`center`}
				sx={{
					textDecoration: underline ? `underline` : `none`,
					fontWeight: bold ? 600 : 400,
					...sx,
				}}>
				{text}
			</MuiLink>
		</RRDLink>
	)
}

export default Link
