import { theme } from '../assets/material/theme'
import { colors, sizing } from '../constants'

export const styles = {
	iconPrimary: {
		fill: theme.palette.primary.main,
		color: colors.primary,
	},
	iconText: {
		fill: colors.text,
		color: colors.text,
	},
	iconMuted: {
		fill: colors.mutedLight,
		color: colors.mutedLight,
	},
	background: {
		backgroundColor: colors.bg,
	},
	backgroundPrimary: {
		backgroundColor: colors.primary,
	},
	backgroundSecondary: {
		backgroundColor: colors.secondary,
	},
	backgroundMuted: {
		backgroundColor: colors.muted,
	},
	backgroundMutedLight: {
		backgroundColor: colors.mutedLight,
	},
	backgroundSuccess: {
		backgroundColor: colors.brand_success,
	},
	backgroundDanger: {
		backgroundColor: colors.danger,
	},
	backgroundWarning: {
		backgroundColor: colors.brand_warning,
	},
	flexCenter: {
		display: `flex`,
		alignItems: `center`,
		justifyContent: `center`,
	},
	sidebar: {
		flexShrink: 0,
		width: `100%`,
		maxWidth: `${sizing.drawerWidth}px`,
		overflowX: `hidden`,
		'& .MuiDrawer-paper': {
			width: `${sizing.drawerWidth}px`,
			backgroundColor: colors.bgSurface,
			boxSizing: 'border-box',
			borderRadius: 0,
			height: `100vh`,
			bottom: 0,
			paddingTop: `${sizing.headerHeight}px`,
			transition: 'transform 0.3s ease-in-out',
		},
	},
	header: {
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			borderRadius: 0,
			backgroundColor: colors.bgSurface,
			display: `flex`,
			flexDirection: `row`,
			alignItems: `center`,
			justifyContent: `space-between`,
			height: sizing.headerHeight,
			minHeight: sizing.headerHeight,
			maxWidth: `100vw`,
		},
		tabs: {
			height: sizing.headerHeight,
			minHeight: sizing.headerHeight,
		},
		tab: {
			height: sizing.headerHeight,
			minHeight: sizing.headerHeight,
			paddingX: `${sizing.themeSpacing}px`,
			paddingY: `${sizing.themeSpacing}px`,
			fontWeight: `normal`,
			color: colors.text,
			whiteSpace: `nowrap`,
		},
	},
}
