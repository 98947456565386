import { Box } from '../../../box/box'
import { SkeletonInputLabel } from '../../../skeleton/skeleton-input'
import React, { memo } from 'react'

interface LabelProps {
	name?: string
	label?: React.ReactNode | string
	isLoading?: boolean
}

const Component: React.FC<LabelProps> = ({ name, label, isLoading }) => {
	return (
		<Box className={`-cdp-input-form-control`}>
			<Box className={`-cdp-input-label-container`}>
				{isLoading ? (
					<React.Fragment>
						<SkeletonInputLabel />
					</React.Fragment>
				) : (
					<label htmlFor={name ?? ``} className={`-cdp-input-label`}>
						{label}
					</label>
				)}
			</Box>
		</Box>
	)
}

export default memo(Component)
