import { AxiosError } from 'axios'
import { jwtDecode } from 'jwt-decode'
import { errorMessages } from '../message/errorMessages'
import { userCurrentStore } from '../store/userCurrentStore'
import { userRegisterStore } from '../store/userRegisterStore'
import { ServerError } from '../types/common'
import { User, UserLoginType, UserPreRegistrationType, UserRegistrationType, UserTokenType, UserValidateType } from '../types/user'
import { getDialingCode, getToken, sleep } from '../utils'
import { TOKEN_TAG } from './../constants'
import { errorHandler } from './errorHandler'
import { Api } from './http'

export const useAuthService = () => {
	const { removeCurrentUser, setCurrentUser } = userCurrentStore()
	const { resetRegisterStoreData, setStoreData } = userRegisterStore()

	async function userCreate(payload: UserPreRegistrationType) {
		setStoreData(payload)
		return Api.post(`/v2/ce_user/search/`, payload)
			.then((response) => {
				return {
					...response?.data,
					message: `Account is ready for registration`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userCreateInformation(payload: UserPreRegistrationType & UserRegistrationType) {
		return Api.post(`/v2/ce_user_create/`, payload)
			.then((response) => {
				return {
					...response.data,
					message: `Account created successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userCreateConfirm(payload: UserLoginType) {
		return Api.post(`/v2​/ce_user​/confirm_user​/`, payload)
			.then(async (response) => {
				return {
					...response.data,
					message: `Registration confirmed successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(
					error,
					// @ts-ignore
					error?.response.data?.detail ?? errorMessages.user.ACCOUNT_NOT_EXISTS
				)
			})
	}

	async function userLogin(payload: UserLoginType) {
		try {
			await userLogout()
			await sleep(1000)
			const response = await Api.post(`/api/token/`, payload)
			if (response?.data?.access) {
				const { user_id } = jwtDecode(response?.data?.access) as UserTokenType
				localStorage.setItem(
					TOKEN_TAG,
					JSON.stringify({
						...response?.data,
						created_at: new Date().toISOString(),
						remember_me: !!payload?.remember_me,
						user_id,
					})
				)
				await sleep(1000)
				const user = await userProfile(user_id)

				if (user) {
					const unconfirmed = user?.error && user.error === 403
					if (unconfirmed) {
						localStorage.removeItem(TOKEN_TAG)
					}
					return {
						...response?.data,
						message: `User login successful`,
						unconfirmed,
					}
				}
			}
			throw new Error(errorMessages.user.ACCOUNT_NOT_EXISTS)
		} catch (error: any) {
			console.log('res:', error?.response)
			if (error?.response?.status === 403) {
				return errorHandler(error, error?.response?.data)
			} else {
				return errorHandler(error, error?.response?.data?.detail ?? errorMessages.user.ACCOUNT_NOT_EXISTS)
			}
		}
	}

	async function userProfile(user_id?: string) {
		user_id = user_id ?? getToken()?.user_id

		if (!user_id) {
			return null
		}

		const cleanContactNumber = (data: any, value: string) => {
			if (!value.startsWith('0')) {
				return value
			}

			const dialingCode = getDialingCode(data['country'])
			return `${dialingCode} ${value.substring(1)}`
		}

		return Api.get(`/v2/ce_user/${user_id}`)
			.then(async (response) => {
				if (response?.data?.id) {
					const data = !response
						? null
						: { ...response.data, contact_number: cleanContactNumber(response.data, response.data['contact_number']) }
					setCurrentUser(data)
					await sleep(1000)
					return data
				} else {
					return null
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				console.error(error)
				return errorHandler(error)
			})
	}

	async function userTokenRefresh(): Promise<any> {
		return Api.post(`/api/token/`)
			.then((response) => {
				return {
					...response.data,
					message: `User token refreshed`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userLogout(): Promise<boolean> {
		removeCurrentUser()
		resetRegisterStoreData()
		window.localStorage.removeItem(TOKEN_TAG)
		window.localStorage.clear()
		await sleep(500)
		return true
	}

	async function userRecoverPassword(payload: UserLoginType) {
		return Api.post(`/v1/password_reset/`, payload)
			.then((response) => {
				return {
					...response.data,
					message: `User recovered successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userOtpValidate(payload: UserValidateType): Promise<any> {
		return Api.post(`/v2/ce_user/otp_validate/`, payload)
			.then((response) => {
				return {
					...response.data,
					message: `Account validated successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userOtpRequest(payload: { email: string; reason: string } | { contact_number: string; reason: string }) {
		return Api.post(`/v2/ce_user_create/otp_request/`, payload)
			.then((response) => {
				return {
					...response.data,
					message: `We have sent a new OTP`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userUpdateProfile(payload: User, user_id?: string) {
		if (!user_id) {
			return null
		}

		return Api.patch(`/v2/ce_user/${user_id}/`, payload)
			.then(async (response) => {
				try {
					await userProfile()
				} catch (error) {
					// @ts-ignore
				}

				return {
					...response.data,
					message: `Profile updated successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userDestroy() {
		return Api.delete(`/v1/user/remove/`)
			.then(async (response) => {
				return {
					...response.data,
					message: `Profile successfully removed`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userResetPassword(email: string) {
		return Api.post(`/v2/password_reset/`, { email })
			.then((response) => {
				return {
					...response.data,
					message: `Reset email sent successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function updateUserType(user_type: string) {
		return Api.post(`/v2/ce_user/update_user_type/`, { user_type })
			.then((response) => {
				return {
					...response.data,
					message: `User type updated successfully`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function userSetResetPassword(password: string, token: string) {
		return Api.post(`/v2/password_reset/confirm/`, { password, token })
			.then((response) => {
				return {
					...response.data,
					message: `Password reset successful`,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	return {
		userProfile,
		userLogout,
		userCreate,
		userCreateInformation,
		userCreateConfirm,
		userLogin,
		userTokenRefresh,
		userRecoverPassword,
		userOtpValidate,
		userOtpRequest,
		userUpdateProfile,
		userDestroy,
		userResetPassword,
		updateUserType,
		userSetResetPassword,
	}
}
