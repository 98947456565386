import { CircularProgress } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { debounce } from 'lodash'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { colors } from '../../../constants'
import { useGoogleService } from '../../../services/google'
import { PlaceInterface } from '../../../types/common'
import { getGooglePlace } from '../../../utils'
import { Box } from '../../box/box'
import { Label } from '../../form-controlled-field/label'
import { SkeletonInput, SkeletonInputLabel } from '../../skeleton/skeleton-input'

interface PlacesProps {
	getAddress?: any
	label?: React.ReactNode
	placeholder?: string
}

const Places: React.FC<PlacesProps> = ({ getAddress, label, placeholder }) => {
	const { placesFind } = useGoogleService()
	const [open, setOpen] = useState(false)
	const [places, setPlaces] = useState<readonly PlaceInterface[]>([])
	const [searchQuery, setSearchQuery] = useState('')
	const [isRendering, setIsRendering] = useState(true)
	const [isSearching, setIsSearching] = useState(false)

	const fetchResults = useCallback(
		debounce(async (searchQuery: string) => {
			if (!searchQuery) {
				setPlaces([])
				setIsSearching(false)
				return
			}

			setIsSearching(true)

			try {
				const response = await placesFind(searchQuery)
				if (response && response?.length) {
					setPlaces(response)
				}
			} catch (error) {
				console.error(error)
			} finally {
				setIsSearching(false)
			}
		}, 500),
		[]
	)

	const handleChange = (address: string) => {
		setSearchQuery(address)
		fetchResults(address)
	}

	function handleSelection(_event: any, address: any) {
		if (getAddress && typeof getAddress === 'function') {
			getAddress(getGooglePlace(address))
		}
	}

	useEffect(() => {
		setIsRendering(false)
	}, [])

	return isRendering ? (
		<React.Fragment>
			<SkeletonInputLabel />
			<SkeletonInput />
		</React.Fragment>
	) : (
		<React.Fragment>
			{label && <Label name={`address`} label={label} />}
			<Box className={`-cdp-input-form-control`}>
				<Autocomplete
					sx={{ padding: 0, width: `100%` }}
					className={`-cdp-ce-ui-places`}
					open={open}
					loadingText={`Finding given address or town`}
					noOptionsText={`Address not found`}
					clearOnBlur={true}
					onOpen={() => {
						setOpen(true)
					}}
					onClose={() => {
						setOpen(false)
					}}
					getOptionLabel={(option: PlaceInterface) => option?.formattedAddress ?? option?.shortFormattedAddress}
					popupIcon={
						isSearching ? (
							<CircularProgress color='inherit' size={20} />
						) : (
							<FiSearch size={`20px`} color={colors.text} style={{ transform: `none` }} />
						)
					}
					options={places ?? []}
					loading={isSearching}
					inputValue={searchQuery}
					onChange={handleSelection}
					filterOptions={(places) => places}
					onInputChange={(_event, newSearchQuery) => {
						handleChange(newSearchQuery)
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth={true}
							error={false}
							label={searchQuery ? `` : placeholder ?? `Search by address or town name`}
							className={`-cdp-ce-ui-places-search`}
							InputLabelProps={{
								shrink: false,
								style: {
									color: colors.divider,
								},
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
							}}
							inputProps={{
								...params.inputProps,
								style: { paddingRight: `70px` },
							}}
						/>
					)}
				/>
			</Box>
		</React.Fragment>
	)
}

export default Places
