import { Box, BoxProps } from '@mui/material'
import React from 'react'

type RowProps = BoxProps & {
	center?: boolean
}

const Component: React.FC<RowProps> = ({ children, ...props }) => {
	return (
		<Box display={`flex`} flexDirection={`row`} justifyContent={`center`} alignItems={`center`} {...props}>
			{children}
		</Box>
	)
}

export default Component
