import { Grid, Typography, useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormTitle } from '../../../../components/form-controlled-field/title'
import { DATE_FORMAT, sizing } from '../../../../constants'
import { gender, income, race, southAfricanStates } from '../../../../constants/options'
import { useAppContext } from '../../../../context/Provider'
import { useAuthService } from '../../../../services/user'
import { userRegisterInfoStore } from '../../../../store/userRegisterInfoStore'
import { userRegisterStore } from '../../../../store/userRegisterStore'
import { AddressInterface } from '../../../../types/common'
import { sleep } from '../../../../utils'
import { Button } from '../../../button/button'
import { Checkbox } from '../../../form-controlled-field/checkbox'
import { Country } from '../../../form-controlled-field/country'
import { DateField } from '../../../form-controlled-field/date'
import { Form } from '../../../form-controlled-field/form'
import { Radio } from '../../../form-controlled-field/radio'
import { Select } from '../../../form-controlled-field/select'
import { TextField } from '../../../form-controlled-field/text'
import { Spacer } from '../../../layout/spacer'
import { Link } from '../../../link'
import { Places } from '../../../places'
import { schema } from './src/schema'

export const FormAuthRegisterInformation: React.FC = () => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const { userCreateInformation } = useAuthService()
	const navigate = useNavigate()
	const { setGlobalLoader, setSnackbar, setDialog } = useAppContext()
	const { formData } = userRegisterInfoStore()
	const {
		formData: { first_name, last_name, contact_number, email, password },
	} = userRegisterStore()

	useEffect(() => {
		if (!first_name || !last_name || !contact_number || !email || !password) {
			navigate(`/register`)
		}
	}, [])

	const handleTerms = (e: any) => {
		setDialog({
			open: true,
			title: `Please take note`,
			description: `You are leaving the 'Absa Consumer Financial Education website' and will be re-directed to the Absa website which communicates all of the terms and conditions`,
			onConfirm: () => window.open(`https://www.absa.co.za/legal-and-compliance/terms-of-use/`, '_blank'),
			setDialog,
			confirmTitle: 'Accept',
			variant: 'info',
		})
	}

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={formData}
				validationSchema={schema}
				onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
					setGlobalLoader(true)
					userCreateInformation({
						...values,
						first_name,
						last_name,
						contact_number,
						email,
						password,
						user_type_raw: 'cfe',
						username: email,
						dob: dayjs(values.dob).format(DATE_FORMAT),
						confirmed: false,
					}).then(async ({ error, message, errorMessageFieldNames }: any) => {
						if (errorMessageFieldNames?.length) {
							errorMessageFieldNames.map((field: string) => setFieldError(field, message))
						}

						setGlobalLoader(false)
						if (error) {
							setStatus({
								error: message,
							})
							setSubmitting(false)
							setSnackbar({
								open: true,
								message: `${message}`,
								severity: `error`,
							})
						} else {
							setStatus({
								success: `${message}`,
							})
							setSnackbar({
								open: true,
								message: `${message}`,
								severity: `success`,
							})
							await sleep(2000)
							setSubmitting(false)
							navigate(`/validate`, {
								state: {
									email,
									reason: 'registration',
								},
							})
						}
					})
				}}>
				{({ values, status, isSubmitting, setFieldValue }) => {
					const southAfricanResident: boolean = values.country === `South Africa`
					const southAfrican: boolean = values.country_citizenship === `South Africa`
					return (
						<React.Fragment>
							<FormTitle
								title={`Create an account`}
								subTitle={`Profile information`}
								nextStep={``}
								progress={95}
								status={status}
							/>
							<Form>
								<Country name={`country`} label={`Country of residence`} placeholder={`Select country`} />
								<Country name={`country_citizenship`} label={`Citizenship`} placeholder={`Select country`} />
								<DateField name={`dob`} label={`Date of birth`} />
								<Radio name={`gender`} label={`Gender`} options={gender} />
								<Spacer height={8} />

								<Places
									label={`Residential address`}
									getAddress={({ address, town, stateName, municipality }: AddressInterface) => {
										setFieldValue(`address`, address)
										setFieldValue(`municipality`, municipality)
										setFieldValue(`town`, town)
										setFieldValue(`state`, stateName)
									}}
									placeholder={`Start typing address or town name`}
								/>
								<Spacer height={6} />
								<TextField name={`address`} placeholder={`Address`} plain={true} />
								<TextField name={`location`} placeholder={`Apartment (optional)`} plain={true} />
								<TextField name={`municipality`} placeholder={`Municipality`} plain={true} />
								<TextField name={`town`} placeholder={`Area or town`} plain={true} />
								{southAfricanResident ? (
									<Select name={`state`} placeholder={`Select your province`} options={southAfricanStates} plain={true} />
								) : (
									<TextField name={`state`} placeholder={`State or Province`} plain={true} />
								)}
								{southAfrican && (
									<TextField
										name={`id_number`}
										label={`What is your South African ID Number`}
										placeholder={`Enter your SA ID Number`}
									/>
								)}
								{southAfrican && southAfricanResident && (
									<Radio name={`income_over_250k`} label={`How much do you earn per year?`} options={income} />
								)}
								{southAfrican && <Radio name={`race`} label={`Ethnicity`} options={race} />}
								<Checkbox
									name={`terms_accepted`}
									label={
										<Typography>
											I agree to the <Link onClick={handleTerms} to={`#`} text={`Terms and Conditions`} />
										</Typography>
									}
								/>
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1} sx={{ flexDirection: isMobile ? `row` : `row-reverse` }}>
									<Grid item={true} xs={12}>
										<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
											Submit
										</Button>
									</Grid>
									<Grid item={true} xs={12}>
										<Button
											type={`button`}
											variant={`outlined`}
											color={`secondary`}
											onClick={() => {
												navigate(`/register`)
											}}>
											Back
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
