import { AxiosError } from 'axios'
import { ModuleSummary, Quiz, QuizMap } from 'types/cfe_assessment'
import { ServerError } from 'types/common'
import { User } from 'types/user'
import { errorHandler } from './errorHandler'
import { Api } from './http'

export const useCFEAssessmentService = () => {
	async function cfeDownloadCertificate(user: User) {
		return Api.get(`/v2/module/quiz/get_certificate/`, { responseType: 'blob', headers: { 'Content-Type': 'application/pdf' } })
			.then(async (res) => {
				// window.open(URL.createObjectURL(res.data))
				const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
				const link = document.createElement('a')
				link.href = url
				link.target = '_blank'
				link.setAttribute('download', `${user.first_name}-${user.last_name}-certificate.pdf`) //or any other extension
				document.body.appendChild(link)
				link.click()
				return res.data
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function cfeModuleSummaries(): Promise<ModuleSummary[] | null> {
		return Api.get(`/v2/module/module/summary/`)
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function cfeQuiz(quizId: number): Promise<Quiz | null> {
		return Api.get(`/v2/module/quiz/${quizId}/`)
			.then((response) => {
				return {
					...response?.data,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function cfeAnswerQuiz(quizId: number, questionId: number, optionId: number) {
		return Api.post(`/v2/module/quiz/answer/${quizId}/${questionId}/`, { option: optionId })
			.then((response) => {
				return {
					...response?.data,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	async function cfeBulkQuizAnswers(quizId: number, payload: { question: number; option: number }[]) {
		return Api.post(`/v2/module/quiz/answer/${quizId}/create_bulk/`, { answers: payload })
			.then((response) => {
				return {
					...response?.data,
				}
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	/***
	 * Loads the module summary and fetches the courses and quizes
	 */
	async function cfeFullModuleSummaries() {
		return cfeModuleSummaries()
			.then(async (moduleSummaries) => {
				const quizIds = new Set<number>()
				for (const moduleSummary of moduleSummaries) {
					for (const quizId of moduleSummary.quizzes) {
						quizIds.add(quizId)
					}
				}

				const quizes = await Promise.all(Array.from(quizIds.values()).map((quizId_1) => cfeQuiz(quizId_1)))
				const quizMap: QuizMap = {}
				for (const quiz of quizes) {
					quizMap[quiz.id] = quiz
				}
				return moduleSummaries.map((moduleSymmary) => {
					return { module: moduleSymmary, quizzes: quizMap }
				})
			})
			.catch((error: AxiosError<ServerError>) => {
				// @thabang: Handle error better
				errorHandler(error)

				return null
			})
	}

	return {
		cfeDownloadCertificate,
		cfeFullModuleSummaries,
		cfeAnswerQuiz,
		cfeBulkQuizAnswers,
		cfeQuiz,
		cfeModuleSummaries,
	}
}
