import { Box, Grid } from '@mui/material'
import * as React from 'react'
import { colors, sizing } from '../../../constants'
import { ButtonAppStore } from '../../button/appstore'
import { Spacer } from '../../layout/spacer'

const Component: React.FC = () => {
	return (
		<Box
			borderRadius={`${sizing.borderRadius}px`}
			sx={{
				backgroundColor: colors.brand_graphite_8,
			}}>
			<Box>
				<Spacer height={sizing.themeSpacing * 2} />
				<Box m={2}>
					<Grid container={true} spacing={1}>
						{[
							{
								store: 'google',
								url: 'https://play.google.com/store/apps/details?id=absa.readytowork&',
							},
							{
								store: 'huawei',
								url: 'https://appgallery.huawei.com/app/C104833943',
							},
							{
								store: 'apple',
								url: 'https://apps.apple.com/za/app/absa-readytowork/id1586779425',
							},
						].map(({ store, url }: any, i: number) => (
							<Grid item={true} xs={12} md={4} key={i + 1}>
								<ButtonAppStore store={store} url={url} />
							</Grid>
						))}
					</Grid>
				</Box>
			</Box>
			<Spacer />
		</Box>
	)
}

export default Component
