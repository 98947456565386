import { BoxProps, Box as MuiBox } from '@mui/material'
import React from 'react'

type RowProps = BoxProps & {
	backgroundColor?: string | any
	fullWidth?: boolean
}

export const Box: React.FC<RowProps> = ({
	children,
	backgroundColor,
	fullWidth,
	...props
}) => {
	return (
		<MuiBox
			style={{
				backgroundColor: backgroundColor ?? 'transparent',
				width: fullWidth ? `100%` : `inherit`,
			}}
			{...props}>
			{children}
		</MuiBox>
	)
}
