import { ThemeProvider } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Provider } from 'context/Provider'
import { Outlet } from 'react-router-dom'
import Layout from './layout'

function Routes() {
	return (
		<ThemeProvider theme={theme}>
			<Provider>
				<Layout>
					<Outlet />
				</Layout>
			</Provider>
		</ThemeProvider>
	)
}

export default Routes
