import { sizing } from '../../constants'
import { Skeleton, Stack } from '@mui/material'
import React from 'react'
import { Spacer } from '../layout/spacer'

export const SkeletonInput: React.FC = () => {
	return (
		<Stack
			spacing={1}
			sx={{
				display: `flex`,
				justifyContent: `center`,
				alignItems: `center`,
				flexDirection: `column`,
				width: `100%`,
			}}>
			<Skeleton
				variant={`rounded`}
				width={`100%`}
				height={sizing.inputHeight}
			/>
		</Stack>
	)
}

export function labelWidth(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1)) + min
}

export const SkeletonInputLabel: React.FC<{ center?: boolean }> = ({
	center,
}) => {
	return (
		<Stack
			spacing={1}
			sx={{
				display: `flex`,
				justifyContent: `center`,
				alignItems: center ? `center` : `flex-start`,
				flexDirection: `column`,
				width: `100%`,
			}}>
			<Skeleton
				variant={`rounded`}
				width={`${labelWidth(30, 90)}%`}
				height={sizing.themeSpacing * 1.5}
			/>
			<Spacer height={sizing.themeSpacing * 0.2} />
		</Stack>
	)
}
