import { AppBar, Hidden, Typography, useMediaQuery } from '@mui/material'

import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import * as React from 'react'
import { colors, sizing } from '../../../constants'
import { styles } from '../../../helpers/styles'
import { useRoutes } from '../../../hooks/useRoutes'
import { userCurrentStore } from '../../../store/userCurrentStore'
import { RouteProps } from '../../../types/common'
import { IconButton } from '../../button/icon-button'
import { Row } from '../../grid'
import { Icon } from '../../icon'
import { Link } from '../../link'
import { HeaderLink } from './Component.link'

interface HeaderProps {
	mobileMenuToggle?: () => void
	mobileMenuOpen?: boolean
}

const Header: React.FC<HeaderProps> = ({ mobileMenuToggle, mobileMenuOpen }) => {
	const { routes } = useRoutes()
	const { currentUser } = userCurrentStore()

	function getRightLink(path: string) {
		return routes.filter((o) => o?.path === path)
	}

	return (
		<AppBar position={`fixed`} variant={`elevation`} square={true} elevation={3} sx={styles.header.appBar}>
			<Row>
				<Row justifyContent={`flex-start`} paddingLeft={`${sizing.themeSpacing}px`} whiteSpace={`nowrap`} alignItems='stretch'>
					<Link to={`/`} text={<Logo />} />
					<Box
						marginY={theme.spacing(0.25)}
						marginLeft={theme.spacing(1.8)}
						borderLeft={1}
						borderColor='#979797'
						display='flex'
						alignItems='center'>
						<Typography
							fontWeight={`bolder`}
							marginLeft={theme.spacing(1.1)}
							marginRight={`${sizing.themeSpacing * 2}px`}
							whiteSpace={`nowrap`}>
							Absa Digital Hub
						</Typography>
					</Box>
				</Row>
				<Hidden implementation={`css`} mdDown>
					<Row justifyContent={`flex-start`}>
						{routes
							.filter((o) => o?.placement?.includes(`header-left`))
							.map(({ label, icon, path, focus }: RouteProps, i: number) => (
								<HeaderLink key={i + 1} label={label} path={path} icon={icon} active={focus} />
							))}
					</Row>
				</Hidden>
			</Row>
			<Row justifyContent={`flex-end`}>
				{currentUser ? (
					<React.Fragment>
						{getRightLink(`account`).map(({ path, label, icon, focus }: RouteProps, i: number) => (
							<Hidden implementation={`css`} mdDown key={i + 2}>
								<HeaderLink icon={icon} label={label} path={path} active={focus} />
							</Hidden>
						))}
						{getRightLink(`logout`).map(({ label, icon, path, focus }: RouteProps, i: number) => (
							<HeaderLink key={i + 1} label={label} icon={icon} path={path} divider={[`left`]} active={focus} />
						))}
					</React.Fragment>
				) : (
					<React.Fragment>
						{getRightLink(`register`).map(({ path, focus, label, icon }: RouteProps, i: number) => (
							<Hidden implementation={`css`} mdDown key={i + 1}>
								<HeaderLink active={focus} label={label} path={path} icon={icon} />
							</Hidden>
						))}
						{getRightLink(`login`).map(({ label, focus, icon, path }: RouteProps, i: number) => (
							<HeaderLink key={i + 1} label={label} icon={icon} path={path} divider={[`left`]} active={focus} />
						))}
					</React.Fragment>
				)}
				<Hidden implementation={`css`} mdUp>
					<Row borderLeft={`1px solid ${colors.muted}`} height={32} width={42} paddingLeft={`${sizing.themeSpacing / 2}px`}>
						<IconButton onClick={mobileMenuToggle}>
							<Icon name={mobileMenuOpen ? `close` : `hamburger`} width={`24px`} height={`24px`} />
						</IconButton>
					</Row>
				</Hidden>
			</Row>
		</AppBar>
	)
}

const Logo = () => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm')) ?? true
	const { currentUser } = userCurrentStore()

	const getLogoSize = () => {
		return isMobile ? 40 : 48
	}

	const isSecondaryLogo = () => {
		return currentUser == null
	}

	return (
		<Box display='flex'>
			{isSecondaryLogo() ? (
				<Box marginX={theme.spacing(1)}>
					<Icon name={`logoFilledSecondary`} height={getLogoSize()} />
				</Box>
			) : (
				<Box marginX={theme.spacing(1.2)}>
					<Icon name={`logoFilled`} height={getLogoSize()} />
				</Box>
			)}
		</Box>
	)
}

export default Header
