import { LoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material'
import React, { useEffect, useState } from 'react'

type ButtonMirrorProps = ButtonProps & {
	disabled?: boolean
	isLoading?: boolean
}

export const Button: React.FC<ButtonMirrorProps> = ({ children, disabled, isLoading, ...props }) => {
	const [isRendering, setIsRendering] = useState(true)

	useEffect(() => {
		setIsRendering(false)
	}, [])

	return (
		<LoadingButton type={`button`} fullWidth={true} {...props} loading={isLoading} disabled={disabled ?? isRendering ?? isLoading}>
			{children}
		</LoadingButton>
	)
}
