import { LinearProgressProps, LinearProgress as MuiLinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/styles'

import { colors } from '../../../constants'

const BorderLinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: 'rgba(240, 50, 90, 0.2)',
		//   opacity: .2
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: colors.brand_passion,
		//   opacity: 1
	},
}))

interface DeterminateProgressInterface extends LinearProgressProps {
	height?: number
	value: number
}

const DeterminateProgress: React.FC<DeterminateProgressInterface> = ({ height = 8, color = 'secondary', value }) => {
	return (
		<BorderLinearProgress
			variant='determinate'
			sx={{
				height: height,
			}}
			value={value}
		/>
	)
}

export default DeterminateProgress
