import { Form, FormikContextType, useFormikContext } from 'formik'
import { startCase } from 'lodash'
import React, { ReactNode, memo, useEffect } from 'react'
import { useAppContext } from '../../../../context/Provider'
import { Box } from '../../../box/box'

interface InputFieldWrapperProps {
	children: ReactNode
	hideSnackbar?: boolean
	onValuesChange?: (context: FormikContextType<any>) => void
}

const Component: React.FC<InputFieldWrapperProps> = ({ children, hideSnackbar = false, onValuesChange }) => {
	const { setSnackbar } = useAppContext()
	const formikContext = useFormikContext()
	const { submitCount, errors, setStatus, setFieldError, values } = formikContext

	useEffect(() => {
		if (submitCount > 0 && Object.keys(errors).length > 0) {
			const name = Object.keys(errors)[0]
			const error = String(Object.values(errors)[0] || `${startCase(name)} is not valid`)
			setFieldError(name, error)
			setStatus({
				error,
			})

			if (!hideSnackbar) {
				setSnackbar({
					open: true,
					message: error,
					severity: `error`,
				})
			}
		}
	}, [errors, submitCount])

	useEffect(() => {
		if (onValuesChange) {
			onValuesChange(formikContext)
		}
	}, [values])

	return (
		<Box component={Form} fullWidth={true}>
			{children}
		</Box>
	)
}

export default memo(Component)
