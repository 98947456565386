import axios from 'axios'
import { API_URL, TOKEN_LOADER_TAG } from '../constants'
import { getToken } from '../utils'

const axiosInstance = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache, no-store, must-revalidate',
	},
	withCredentials: false,
})

axiosInstance.interceptors.request.use(
	(config) => {
		const token = getToken()
		if (token?.access) {
			config.headers['Authorization'] = `Bearer ${token?.access}`
		}

		localStorage.setItem(TOKEN_LOADER_TAG, 'true')

		return config
	},
	(error) => {
		localStorage.removeItem(TOKEN_LOADER_TAG)
		console.error(error)
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	(response) => {
		localStorage.removeItem(TOKEN_LOADER_TAG)
		return response
	},
	(error) => {
		localStorage.removeItem(TOKEN_LOADER_TAG)
		console.error(error)
		return Promise.reject(error)
	}
)

export { axiosInstance as Api }
