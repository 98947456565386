import { Link } from 'components/link'
import { Option } from '../types/common'

type DialingCode = { name: string; countryCode: string; maxLengths: number[] }
export const permittedDialingCodes: { [key: string]: DialingCode } = {
	'+267': {
		name: 'Botswana',
		countryCode: 'BW',
		maxLengths: [7, 8],
	},
	'+233': {
		name: 'Ghana',
		countryCode: 'GH',
		maxLengths: [9],
	},
	'+254': {
		name: 'Kenya',
		countryCode: 'KE',
		maxLengths: [9],
	},
	'+230': {
		name: 'Mauritius',
		countryCode: 'MU',
		maxLengths: [7, 8],
	},
	'+258': {
		name: 'Mozambique',
		countryCode: 'MZ',
		maxLengths: [9],
	},
	'+248': {
		name: 'Seychelles',
		countryCode: 'SC',
		maxLengths: [7],
	},
	'+27': {
		name: 'South Africa',
		countryCode: 'ZA',
		maxLengths: [9],
	},
	'+255': {
		name: 'Tanzania',
		countryCode: 'TZ',
		maxLengths: [9],
	},
	'+256': {
		name: 'Uganda',
		countryCode: 'UG',
		maxLengths: [9],
	},
	'+260': {
		name: 'Zambia',
		countryCode: 'ZM',
		maxLengths: [9],
	},
}

export const options: Option[] = [
	{
		label: 'label',
		value: 'value',
	},
]

export const race: Option[] = [
	{
		label: 'African',
		value: 'african',
	},
	{
		label: 'Coloured',
		value: 'coloured',
	},
	{
		label: 'White',
		value: 'white',
	},
	{
		label: 'Indian',
		value: 'indian',
	},
	{
		label: 'Chinese',
		value: 'chinese',
	},
	{
		label: 'Other',
		value: 'other',
	},
]

export const gender: Option[] = [
	{
		label: 'Male',
		value: 'male',
	},
	{
		label: 'Female',
		value: 'female',
	},
	{
		label: 'Other',
		value: 'other',
	},
	{
		label: 'Prefer not to say',
		value: 'abstain',
	},
]

export const title: Option[] = [
	{
		label: 'Mr',
		value: 'mr',
	},
	{
		label: 'Mrs',
		value: 'mrs',
	},
	{
		label: 'Ms',
		value: 'ms',
	},
	{
		label: 'Mx',
		value: 'mx',
	},
]

export const category: Option[] = [
	{
		label: 'Student',
		value: 'student',
	},
	{
		label: 'Learner',
		value: 'learner',
	},
	{
		label: 'Employed',
		value: 'employed',
	},
	{
		label: 'Unemployed',
		value: 'unemployed',
	},
]

export const source: Option[] = [
	{
		label: 'All the campaigns as displayed on the website',
		value: 'campaigns',
	},
	{
		label: 'Social Media',
		value: 'socialMedia',
	},
	{
		label: 'Radio',
		value: 'radio',
	},
	{
		label: 'Television',
		value: 'television',
	},
	{
		label: 'Other',
		value: 'other',
	},
]

export const income: Option[] = [
	{
		label: 'Less than R250 000 (ZAR)',
		value: false,
	},
	{
		label: 'More than R250 000 (ZAR)',
		value: true,
	},
]

export const southAfricanStates: Option[] = [
	{ label: 'Eastern Cape', value: 'Eastern Cape' },
	{ label: 'Free State', value: 'Free State' },
	{ label: 'Gauteng', value: 'Gauteng' },
	{ label: 'KwaZulu-Natal', value: 'KwaZulu-Natal' },
	{ label: 'Limpopo', value: 'Limpopo' },
	{ label: 'Mpumalanga', value: 'Mpumalanga' },
	{ label: 'Northern Cape', value: 'Northern Cape' },
	{ label: 'North West', value: 'North West' },
	{ label: 'Western Cape', value: 'Western Cape' },
]

export const support = [
	// Generic questions
	{
		title: 'Can I make changes to or update my profile?',
		modes: ['generic', 'cfe'],
		description:
			'Yes, changes or updates to your profile can be made. On the profile page, the sections that can be updated will be indicated.  Any changes to your mobile number or email address will need to be verified using the OTP process. You will receive the OTP via either email address or mobile, depending on which of the two is changed.',
	},
	{
		title: 'How is my personal data used?',
		modes: ['generic', 'cfe'],
		description:
			'We take your privacy seriously. Your personal data is only used for the purpose of providing you with the e-learning programme you have registered for. We adhere to strict security measures to ensure your information remains safe and confidential.',
	},
	{
		title: 'What happens if I forget my password?',
		modes: ['generic', 'cfe'],
		description:
			'You need to click on Forgot password. On the Forgot password screen, enter your email address and submit. Check your mailbox for the reset password email that will contain a link to reset your password. Choose and confirm your new password. You can now log back into your chosen learning.',
	},
	{
		title: 'I still need help',
		modes: ['generic', 'cfe'],
		email: 'cfe.support@cloudsmiths.co.za',
		description: (
			<span>
				If you require further assistance or have any other queries not addressed here, feel free to contact our customer support
				team. We're here to help you with any banking-related issues or concerns you may have. You can reach us through email{' '}
				<Link to='mailto:cfe.support@cloudsmiths.co.za' text='cfe.support@cloudsmiths.co.za' />{' '}
			</span>
		),
	},
	// CDP Questions
	{
		title: 'What is Consumer Financial Education?',
		modes: ['cfe'],
		description:
			'Consumer Financial Education is the process of transferring knowledge and skills to consumers, future consumers and potential consumers for individual well-being and the public good.',
	},
	{
		title: 'What does the Consumer Financial Education course offer?',
		modes: ['cfe'],
		description:
			'Consumer Financial Education provides knowledge and understanding to individuals on money management, savings and investments as well as managing credit and risk.',
	},
	{
		title: 'How does the course learning journey work?',
		modes: ['cfe'],
		description:
			'It is required that all participants in this course must complete  a pre-assessment of their financial literacy knowledge. After the pre-assessment is done, the participant will engage in the content of the course and when completed, a post-assessment is done to finalise the course.',
	},
	{
		title: 'Who is Consumer Financial Education aimed at?',
		modes: ['cfe'],
		description:
			'Consumer Financial Education is aimed at empowering the broader consumer with knowledge to enable them to make more informed decisions about their finances and lifestyles.  All individuals earning less than R250 000 per annum are encouraged to complete this course as well as school learners and youth from the age of 18 years old.',
	},
	{
		title: 'How much does the Consumer Financial Education course Cost?',
		modes: ['cfe'],
		description: 'There are no costs associated with this course.',
	},
	{
		title: 'How long will it take to complete a short course?',
		modes: ['cfe'],
		description: 'A minimum of 2 up to 5 hours are required to complete this course.',
	},
	{
		title: 'Can I download the course material?',
		modes: ['cfe'],
		description:
			'The Absa Consumer Financial Education course is available as a PDF download for offline learning purposes. Please note that, as a registered user, you cannot share, copy, amend or distribute the course learning material or use it for your own commercial benefit.',
	},
	{
		title: 'How much do I need to achieve to pass the course?',
		modes: ['cfe'],
		description:
			'There’s no pass requirement applicable to the Consumer Financial Education course. The intended outcome of the course is to develop the individual’s knowledge and understanding of the financial sector and its products and services.',
	},
	{
		title: 'How do I download my certificate of completion?',
		modes: ['cfe'],
		description:
			'Individuals can download a certificate of completion immediately after completing the post-assessment of the Consumer Financial Education training course.',
	},
]
