import { AxiosError } from 'axios'
import { ServerError } from 'types/common'
import { Dashboard } from 'types/dashboard'
import { errorHandler } from './errorHandler'
import { Api } from './http'

export const useDashboardService = () => {
	async function getDashboardSummary(): Promise<Dashboard | null> {
		return Api.get(`/v2/dashboard/summary/`)
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				return errorHandler(error)
			})
	}

	return { getDashboardSummary }
}
