import { permittedDialingCodes } from 'constants/options'
import { getIn, useFormikContext } from 'formik'
import { MuiTelInput, MuiTelInputCountry } from 'mui-tel-input'
import * as React from 'react'
import { memo } from 'react'
import { InputFieldWrapper } from '../../wrapper'

interface FieldProps {
	name: string
	label?: React.ReactNode | string
	placeholder?: string
	hint?: React.ReactNode | string
}

const Component: React.FC<FieldProps> = ({ name, placeholder, label, hint }) => {
	const defaultCountry = `ZA`
	const { values, errors, touched, setFieldValue } = useFormikContext()
	const value = getIn(values, name)
	const error = getIn(errors, name)
	const touch = getIn(touched, name)

	const handleChange = (stringValue: string) => {
		setFieldValue(name, stringValue)
	}

	const onlyCountries = Object.values(permittedDialingCodes).map((item) => item.countryCode) as MuiTelInputCountry[]

	return (
		<InputFieldWrapper name={name} hint={hint} label={label}>
			<MuiTelInput
				defaultCountry={defaultCountry}
				onlyCountries={onlyCountries}
				fullWidth={true}
				inputMode={`tel`}
				label={null}
				className={`-cdp-ce-phone-input`}
				placeholder={placeholder || `Phone Number`}
				error={touch && error}
				autoComplete={`phone-number`}
				value={value}
				onChange={handleChange}
				sx={{
					paddingLeft: 0,
				}}
			/>
		</InputFieldWrapper>
	)
}

export default memo(Component)
