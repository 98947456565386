import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { getIn, useFormikContext } from 'formik'
import * as React from 'react'
import { memo } from 'react'
import { colors } from '../../../../constants'
import { InputFieldWrapper } from '../../wrapper'

interface FieldProps {
	name: string
	label?: React.ReactNode | string
	hint?: React.ReactNode | string
	minDate?: Dayjs
	maxDate?: Dayjs
}

const Component: React.FC<FieldProps> = ({ name, hint, label, minDate, maxDate }) => {
	const { values, errors, touched, setFieldValue } = useFormikContext()
	const today = new Date()
	const value = getIn(values, name) || today
	const error = getIn(errors, name)
	const touch = getIn(touched, name)
	const hasError = value && error && touch

	return (
		<InputFieldWrapper name={name} hint={hint} label={label} className={clsx(`-cdp-ce-date-input`, hasError && `-date-input-error`)}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					value={dayjs(value)}
					sx={{
						width: `100%`,
						paddingRight: 0,
						color: hasError ? colors.danger : colors.text,
						input: {
							color: hasError ? colors.danger : value === today ? colors.grey : colors.text,
						},
						'.MuiInputBase-root fieldset': {
							borderColor: hasError ? colors.danger : colors.text,
						},
					}}
					format={`DD MMMM, YYYY`}
					minDate={minDate}
					maxDate={maxDate}
					onChange={(newValue) => setFieldValue(name, newValue)}
				/>
			</LocalizationProvider>
		</InputFieldWrapper>
	)
}

export default memo(Component)
