import { FormikHelpers } from 'formik'
import { useAuthService } from 'services/user'

export const useResetPassword = (props?: { validate?: boolean }) => {
	const { userResetPassword } = useAuthService()

	async function handleSubmit(values: any, { setFieldValue, setStatus }: FormikHelpers<any>) {
		setStatus({})
		await userResetPassword(values['email'])
		setFieldValue('submitted', true)
	}

	return {
		handleSubmit,
	}
}
