import { Box, BoxProps } from '@mui/material'
import React from 'react'

type ColumnProps = BoxProps & {
	center?: boolean
}

const Component: React.FC<ColumnProps> = ({ children, center, ...props }) => (
	<Box
		display={`flex`}
		flexDirection={`column`}
		justifyContent={center ? `center` : undefined}
		alignItems={center ? `center` : undefined}
		{...props}>
		{children}
	</Box>
)

export default Component
