import { Container } from '@mui/material'
import React from 'react'
import { FormAuthRegister } from '../../../components/form-controlled/register/register/form'
import { RegistrationFooter } from '../components/register-footer'

export default function PageRegisterCredentials() {
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormAuthRegister />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
