import { Box } from '@mui/material'
import { useAppContext } from 'context/Provider'
import { FC, useEffect } from 'react'
import { useScormService } from 'services/scorm'
import { userCurrentStore } from 'store/userCurrentStore'
import { MODULE_COURSE_NAME_FOLDER } from 'types/cfe_assessment'
import SCORMAdapter from '../../../../../../../helpers/scorm_api_adapter'

declare global {
	interface Window {
		API: any
	}
}

interface IProps {
	moduleName: string
	backToModuleList: () => void
}

const ScormModuleView: FC<IProps> = ({ moduleName, backToModuleList }) => {
	const { setGlobalLoader } = useAppContext()
	const { currentUser } = userCurrentStore()
	const scormService = useScormService()
	window.API = new SCORMAdapter(moduleName, currentUser, scormService, backToModuleList)

	useEffect(() => {
		setGlobalLoader(true)
		scormService
			.commit({}, moduleName)
			.then((response: any) => {
				if (!response.success) {
					console.log('Initial SCORM Commit Failed.')
				}
			})
			.catch((error: any) => {
				console.log(error)
			})

		const scormKey = 'scormData'
		scormService
			.initData(moduleName)
			.then((response: any) => {
				if (!response) {
					if (!window.localStorage.getItem(scormKey)) {
						window.localStorage.setItem(scormKey, '{}')
					}
				} else {
					window.localStorage.setItem(scormKey, JSON.stringify(response))
				}
				setGlobalLoader(false)
			})
			.catch((error: any) => {
				console.log(error)
			})
	}, [])

	return (
		<Box height='100%' color='pink'>
			<iframe
				id='scorm'
				src={`/scorm/${MODULE_COURSE_NAME_FOLDER}/scormdriver/indexAPI.html`}
				width='100%'
				height='100%'
				title={'Scorm Render'}
				style={{ border: 0 }}
			/>
		</Box>
	)
}

export default ScormModuleView
