import { pick } from 'lodash'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { STORE } from '../constants'
import { user } from '../constants/user'
import { User, UserPreRegistrationType } from '../types/user'

export interface FormStore {
	formData: UserPreRegistrationType
	setStoreData: (_data: Partial<User>) => void
	resetRegisterStoreData: () => void
}

export const initialState: FormStore = {
	formData: pick(user, ['first_name', 'last_name', 'email', 'username', 'contact_number', 'password']),
	setStoreData: () => {},
	resetRegisterStoreData: () => {},
}

export const userRegisterStore = create<FormStore>()(
	devtools(
		persist(
			(set) => ({
				...initialState,
				setStoreData: (data: any) =>
					set((state: Pick<FormStore, 'formData'>) => ({
						formData: { ...state.formData, ...data },
					})),
				resetRegisterStoreData: () => set({ formData: initialState.formData }),
			}),
			{
				name: STORE.USER_REGISTRATION,
				getStorage: () => localStorage,
			}
		)
	)
)
