import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { pick } from 'lodash'
import * as React from 'react'
import { sizing } from '../../../../constants'
import { income, southAfricanStates } from '../../../../constants/options'
import { userCurrentStore } from '../../../../store/userCurrentStore'
import { AddressInterface } from '../../../../types/common'
import { Button } from '../../../button/button'
import { Country } from '../../../form-controlled-field/country'
import { Form } from '../../../form-controlled-field/form'
import { Phone } from '../../../form-controlled-field/phone'
import { Radio } from '../../../form-controlled-field/radio'
import { Select } from '../../../form-controlled-field/select'
import { TextField } from '../../../form-controlled-field/text'
import { FormTitle } from '../../../form-controlled-field/title'
import { Spacer } from '../../../layout/spacer'
import { Places } from '../../../places'
import { useProfileUpdate } from '../useProfileUpdate'
import { schema } from './src/schema'

export const FormConfirmDetails: React.FC = () => {
	const { handleConfirmSubmit, handleCountryChange } = useProfileUpdate({ validate: true })
	const { currentUser } = userCurrentStore()

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={pick(currentUser, [
					'first_name',
					'last_name',
					'country',
					'country_citizenship',
					'address',
					'municipality',
					'town',
					'state',
					'contact_number',
					'income_over_250k',
				])}
				validationSchema={schema}
				onSubmit={handleConfirmSubmit}>
				{({ values, status, isSubmitting, setFieldValue }) => {
					const southAfricanResident: boolean = values.country === `South Africa`
					const southAfrican: boolean = values.country_citizenship === `South Africa`
					return (
						<React.Fragment>
							<FormTitle
								title={`Confirm your personal information`}
								description={`Please review your personal & profile information and update any of the information that may have changed`}
								status={status}
							/>
							<Form>
								<TextField name={`first_name`} label={`First Name(s)`} placeholder={`e.g Johnathan`} disabled />
								<TextField name={`last_name`} label={`Last Name`} placeholder={`e.g Smith`} />
								<Country
									name={`country`}
									label={`Country of residence`}
									placeholder={`Select country`}
									afterChange={handleCountryChange}
								/>
								<Spacer height={sizing.themeSpacing * 3} />

								<Places
									label={`Residential address`}
									getAddress={({ address, town, stateName, municipality }: AddressInterface) => {
										setFieldValue(`address`, address)
										setFieldValue(`municipality`, municipality)
										setFieldValue(`town`, town)
										setFieldValue(`state`, stateName)
									}}
								/>
								<Spacer height={6} />

								<TextField name={`address`} placeholder={`Address`} plain={true} />
								<TextField name={`location`} placeholder={`Apartment (optional)`} plain={true} />
								<TextField name={`municipality`} placeholder={`Municipality`} plain={true} />
								<TextField name={`town`} placeholder={`Area or town`} plain={true} />
								{southAfricanResident ? (
									<Select name={`state`} placeholder={`Select your province`} options={southAfricanStates} plain={true} />
								) : (
									<TextField name={`state`} placeholder={`State or Province`} plain={true} />
								)}

								<Spacer height={6} />
								<Phone name={`contact_number`} label={`Contact number`} />
								{southAfrican && southAfricanResident && (
									<Radio name={`income_over_250k`} label={`How much do you earn per year?`} options={income} />
								)}
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container={true} spacing={1}>
									<Grid item={true} xs={12}>
										<Button
											type={`submit`}
											isLoading={isSubmitting}
											variant={`contained`}
											color={`secondary`}
											onClick={() => {}}>
											Confirm
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
