import { Container, Typography } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import { Button } from 'components/button/button'
import { Row } from 'components/grid'
import { Illustration } from 'components/illustration'
import { Spacer } from 'components/layout/spacer'
import { RtwAppDownload } from 'components/rtw-app-download'
import { Support } from 'components/support'
import React from 'react'
import { UserTokenType } from 'types/user'
import { RTW_URL, TOKEN_TAG } from '../../../../../../constants'
import { useAuthService } from 'services/user'
import { userCurrentStore } from 'store/userCurrentStore'

interface CfeGuestProps {}

export default function Component(props: CfeGuestProps) {
	const tokenStore = window.localStorage.getItem(TOKEN_TAG)
	const token = tokenStore ? JSON.parse(tokenStore) : ({} as UserTokenType)
	const { updateUserType } = useAuthService()
	const { currentUser, setCurrentUser } = userCurrentStore()

	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<Spacer height={theme.spacing(3)} />
				<Row justifyContent={`center`}>
					<Box>
						<Illustration name={`graduationOpenDoor`} width={`100%`} />
					</Box>
				</Row>
				<Spacer height={theme.spacing(4)} />
				<Typography variant={`h3`} fontWeight={600} textAlign={`center`}>
					Absa ReadytoWork
				</Typography>

				<Typography variant={`medium`} component='div'>
					Absa ReadytoWork is an education and skills development initiative designed to help young people go from learning to
					earning. It provides access to online career guidance, skills-related short courses and job search tools. Take advantage
					of a wealth of knowledge and resources to boost your employability and develop the skills that many people don't have
					and weren't taught in school.
				</Typography>
				<Spacer height={theme.spacing(3)} />
				<Typography variant={`medium`} component='div'>
					Your most rewarding career is one that is best suited to your interests, skills and personality. Maximising your
					potential and making informed decisions will help to set you on the pathway to career success.
				</Typography>

				<Spacer height={theme.spacing(4)} />
				<Button
					variant={`contained`}
					color={`secondary`}
					onClick={() => {
						if (Object.keys(token).length === 0) {
							window.location.href = `${RTW_URL}`
						} else {
							updateUserType('rtw').then((response) => {
								setCurrentUser({
									...currentUser,
									user_type_raw: response.user_type_raw,
								})
							})

							window.open(
								`${RTW_URL}/?token=${btoa(
									JSON.stringify({
										access: token.access,
										refresh: token.refresh,
										confirmed: token.confirmed,
									})
								)}`,
								'_blank'
							)
						}
					}}>
					Already a ReadytoWork User, login here
				</Button>
				<Spacer height={theme.spacing(4)} />
				<Typography variant='large' align='center' component='div'>
					Create an account by downloading the app below
				</Typography>
				<Spacer height={theme.spacing(1)} />
				<RtwAppDownload />
			</Container>
			<Spacer height={theme.spacing(4)} />
			<Support maxWidth='sm' />
		</React.Fragment>
	)
}
