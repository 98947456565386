import { Grid, Typography, useMediaQuery } from '@mui/material'
import { Formik, FormikContextType } from 'formik'
import * as React from 'react'

import { useNavigate } from 'react-router-dom'
import { colors, sizing } from '../../../constants'
import { useAppContext } from '../../../context/Provider'
import { useAuthService } from '../../../services/user'
import { userLoginStore } from '../../../store/userLoginStore'
import { isDormantAccount, isRtwUserOnly } from '../../../utils'
import { Button } from '../../button/button'
import { Checkbox } from '../../form-controlled-field/checkbox'
import { Form } from '../../form-controlled-field/form'
import { Password } from '../../form-controlled-field/password'
import { TextField } from '../../form-controlled-field/text'
import { FormTitle } from '../../form-controlled-field/title'
import { Row } from '../../grid'
import { Spacer } from '../../layout/spacer'
import Link from '../../link/src/Component'
import { schema } from './src/schema'

export const FormAuthLogin: React.FC = () => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const { userLogin, userOtpRequest } = useAuthService()
	const { setGlobalLoader, setSnackbar, setDialog } = useAppContext()
	const { formData } = userLoginStore()
	const navigate = useNavigate()

	React.useEffect(() => {
		// Hack: Need to find a better approach supporting other pages
		const accountReset = localStorage.getItem('account-reset')
		if (accountReset === 'true') {
			localStorage.removeItem('account-reset')
			setSnackbar({
				open: true,
				message: 'Password reset successful',
				severity: `success`,
			})
		}
	}, [])

	const hideValuesOnInput = ({ setStatus }: FormikContextType<any>) => {
		setStatus(null)
	}

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={formData}
				validationSchema={schema}
				validateOnChange={false}
				onSubmit={(values, { setStatus, setSubmitting }) => {
					setGlobalLoader(true)
					userLogin(values).then(async (res: any) => {
						setGlobalLoader(false)
						if (res?.error) {
							setStatus({
								error: res?.message,
							})
							setSubmitting(false)
							setSnackbar({
								open: true,
								message: res?.message,
								severity: `error`,
							})
						} else {
							setSubmitting(false)

							if (!res.access) {
								navigate('/login')
							} else if (res.access && isRtwUserOnly(res?.user_type)) {
								navigate('/account/update-details')
							} else if (res.access && isDormantAccount(res?.previous_login)) {
								navigate('/login/confirm-details')
							} else if (res?.unconfirmed) {
								const otpPayload = {
									email: values.username,
									reason: 'registration',
								}
								const goToValidation = () => {
									userOtpRequest(otpPayload).then(() => {
										navigate(`/validate`, {
											state: otpPayload,
										})
									})
								}
								setDialog({
									open: true,
									title: 'Account Confirmation',
									description: 'You have not completed the OTP process required to activate your account',
									onConfirm: goToValidation,
									setDialog,
									confirmTitle: 'Activate',
									variant: 'info',
									hideCancel: true,
								})
							} else {
								setStatus({
									success: res?.message,
								})

								setSnackbar({
									open: true,
									message: res?.message,
									severity: `success`,
								})
								navigate('/')
							}
						}
					})
				}}>
				{({ status, isSubmitting, setFieldValue }) => {
					return (
						<React.Fragment>
							<FormTitle title={`Log in`} status={status} />
							<Form onValuesChange={hideValuesOnInput}>
								<React.Fragment>
									<TextField
										trim
										name={`username`}
										label={`Email Address`}
										placeholder={`e.g johnathansmith@gmail.com`}
									/>
									<Password
										name={`password`}
										label={`Password`}
										placeholder={`enter your password`}
										hint={
											<Row justifyContent={`flex-start`}>
												<Typography color={colors.divider}>Need help?</Typography>
												<Spacer width={sizing.themeSpacing} />
												<Link text={`Forgotten Password`} to={`/reset-password`} />
											</Row>
										}
										showStrength={false}
									/>
									<Checkbox name={`remember_me`} label={<Typography>Remember me</Typography>} />
									<Grid container={true} spacing={1} sx={{ flexDirection: isMobile ? `row` : `row-reverse` }}>
										<Grid item={true} xs={12} md={6}>
											<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
												Log in
											</Button>
										</Grid>
										<Grid item={true} xs={12} md={6}>
											<Button
												type={`button`}
												variant={`outlined`}
												color={`secondary`}
												onClick={() => navigate(`/register`)}>
												Create an account
											</Button>
										</Grid>
									</Grid>
								</React.Fragment>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
