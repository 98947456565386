import { Container } from '@mui/material'
import { Box } from 'components/box/box'
import { Support } from 'components/support'
import { ViewProfile } from '../../components/views/single/profile'

export default function PageAccountHome() {
	return (
		<Box display='flex' flexDirection='column' minHeight='100%'>
			<Box flexGrow={1}>
				<Container maxWidth={`md`}>
					<ViewProfile />
				</Container>
			</Box>
			<Support />
		</Box>
	)
}
