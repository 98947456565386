import { Container } from '@mui/material'
import React from 'react'
import { FormAuthRegisterInformation } from '../../../components/form-controlled/register/information/form'
import { RegistrationFooter } from '../components/register-footer'

export default function PageRegisterInformation() {
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormAuthRegisterInformation />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
