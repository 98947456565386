import { Container } from '@mui/material'
import { FormSetResetPassword } from 'components/form-controlled/reset-password/set/form'
import { RegistrationFooter } from 'pages/auth/components/register-footer'
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function PageAccountSetResetPassword() {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	useEffect(() => {
		const token = searchParams.get('token')
		if (token === null) {
			navigate('/reset-password')
		}
	}, [])
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormSetResetPassword />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
