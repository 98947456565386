import { Box, Typography, useMediaQuery } from '@mui/material'
import { Spacer } from 'components/layout/spacer'
import { Rtw } from 'types/dashboard'
import { sizing } from '../../../../../../constants'

interface AppAssessmentStatusProps {
	rtw: Rtw
}

const AppAssessmentStatus: React.FC<AppAssessmentStatusProps> = (props) => {
	const { rtw } = props
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true

	const buildItem = (title: string, value: number) => {
		return (
			<Box sx={{ flex: 1 }}>
				<Box>
					<Typography
						variant={isMobile ? 'medium' : 'large'}
						marginBottom={0}
						lineHeight={`${sizing.themeSpacing * 3}px`}
						fontWeight={600}>
						{value}
					</Typography>
				</Box>
				<Box>
					<Typography variant={isMobile ? 'small' : 'normal'} color='#524A4A'>
						{title}
					</Typography>
				</Box>
			</Box>
		)
	}
	return (
		<Box>
			<Spacer height={sizing.themeSpacing * 2} />
			<Box>
				<Typography variant='h6' sx={{ marginBottom: 0 }}>
					Modules
				</Typography>
			</Box>
			<Spacer height={isMobile ? sizing.themeSpacing * 1 : sizing.themeSpacing * 2} />
			<Box sx={{ display: 'flex' }}>
				{buildItem('Registered', rtw.registered)}
				{buildItem('In progress', rtw.in_progress)}
				{buildItem('Completed', rtw.completed)}
			</Box>
		</Box>
	)
}

export default AppAssessmentStatus
