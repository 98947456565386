import axios from 'axios'
import { REACT_APP_GOOGLE_API_KEY } from '../constants'

export const useGoogleService = () => {
	async function placesFind(textQuery: string = ``): Promise<any> {
		try {
			if (!REACT_APP_GOOGLE_API_KEY) return

			const placeMask = [
				'places.name',
				'places.accessibilityOptions',
				'places.addressComponents',
				'places.adrFormatAddress',
				'places.businessStatus',
				'places.displayName',
				'places.formattedAddress',
				'places.googleMapsUri',
				'places.iconBackgroundColor',
				'places.iconMaskBaseUri',
				'places.location',
				'places.photos',
				'places.plusCode',
				'places.primaryType',
				'places.primaryTypeDisplayName',
				'places.shortFormattedAddress',
				'places.subDestinations',
				'places.types',
				'places.utcOffsetMinutes',
				'places.viewport',
			]

			const raw = JSON.stringify({
				textQuery,
			})

			const config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: 'https://places.googleapis.com/v1/places:searchText',
				headers: {
					'Content-Type': 'application/json',
					'X-Goog-FieldMask': placeMask.join(','),
					'X-Goog-Api-Key': REACT_APP_GOOGLE_API_KEY,
				},
				data: raw,
			}

			const { data } = await axios.request(config)
			return data ? data?.places : []
		} catch (error) {
			console.trace(error)
			return []
		}
	}

	return {
		placesFind,
	}
}
