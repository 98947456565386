import { Container } from '@mui/material'
import { Box } from 'components/box/box'
import { Support } from 'components/support'
import { FormUpdateProfile } from '../../components/form-controlled/account/profile/form'

export default function PageAccountUpdateProfile() {
	return (
		<Box display='flex' flexDirection='column' minHeight='100%'>
			<Box>
				<Container maxWidth={`xs`}>
					<FormUpdateProfile />
				</Container>
			</Box>
			<Support />
		</Box>
	)
}
