import { InputProps as InputPropsInterface, TextField } from '@mui/material'
import { getIn, useFormikContext } from 'formik'
import * as React from 'react'
import { memo } from 'react'
import { InputFieldWrapper } from '../../wrapper'

interface FieldProps {
	disabled?: boolean
	name: string
	label?: React.ReactNode | string
	placeholder?: string
	hint?: React.ReactNode | string
	InputProps?: InputPropsInterface
	plain?: boolean
	multiline?: boolean
	rows?: number | string
	trim?: boolean
}

const Component: React.FC<FieldProps> = ({
	disabled,
	name,
	label,
	hint,
	placeholder,
	InputProps,
	plain,
	multiline,
	rows,
	trim = false,
}) => {
	const { values, errors, touched, setFieldValue } = useFormikContext()
	const value = getIn(values, name)
	const error = getIn(errors, name)
	const touch = getIn(touched, name)

	return (
		<InputFieldWrapper name={name} hint={hint} label={label} plain={plain}>
			<TextField
				disabled={disabled}
				name={name}
				placeholder={placeholder}
				multiline={multiline}
				rows={rows}
				value={value}
				error={touch && error}
				type={'text'}
				fullWidth={true}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					const value = trim ? event.target.value.trim() : event.target.value
					setFieldValue(name, value)
				}}
				InputProps={InputProps}
			/>
		</InputFieldWrapper>
	)
}

export default memo(Component)
