import { Box, CircularProgress as MuiCircularProgress } from '@mui/material'
import * as React from 'react'
import { colors } from '../../../constants'

interface CircularProgressProps {
	value: number
	size?: number
	icon?: React.ReactNode
}

function CustomCircularProgress(props: CircularProgressProps) {
	return (
		<Box sx={{ position: `relative` }} className={`custom-circular-progress`} maxHeight={props.size} overflow={`hidden`}>
			<MuiCircularProgress
				variant='determinate'
				sx={{
					color: colors.brand_graphite_13,
				}}
				thickness={4}
				{...props}
				value={100}
			/>
			<MuiCircularProgress
				variant={`determinate`}
				sx={{
					color: colors.brand_passion,
					animationDuration: '550ms',
					position: 'absolute',
					left: 0,
				}}
				thickness={4}
				{...props}
			/>
		</Box>
	)
}

const CircularProgress: React.FC<CircularProgressProps> = ({ value, size = 45, icon }) => {
	return (
		<Box position={`relative`} display={`inline-flex`}>
			<CustomCircularProgress size={size} value={value} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position={`absolute`}
				display={`flex`}
				alignItems={`center`}
				justifyContent={`center`}>
				{icon}
			</Box>
		</Box>
	)
}

export default CircularProgress
