import { Container, Typography } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import { Button } from 'components/button/button'
import { Row } from 'components/grid'
import { Illustration } from 'components/illustration'
import { Spacer } from 'components/layout/spacer'
import { Support } from 'components/support'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface CfeGuestProps {}

export default function Component(props: CfeGuestProps) {
	const navigate = useNavigate()

	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<Spacer height={theme.spacing(3)} />
				<Row justifyContent={`center`}>
					<Box>
						<Illustration name={`sittingBehindDesk`} width={`100%`} />
					</Box>
				</Row>
				<Spacer height={theme.spacing(4)} />
				<Typography variant={`h3`} fontWeight={600} textAlign={`center`}>
					Consumer Financial Education
				</Typography>

				<Typography variant={`medium`} component='div'>
					Consumer Financial Education is a <b>financial literacy</b> initiative designed to help our users expand their knowledge
					on money matters and understanding their finances better. Create an account or login to access our online course for
					free.
				</Typography>
				<Spacer height={theme.spacing(3)} />
				<Typography variant={`medium`} component='div'>
					You’ll be required to complete a <b>pre-assessment</b> of your financial knowledge to get access to the course content.
					After working through the course content, you'll get another opportunity to test your new level of financial knowledge
					by completing a <b>post-assessment</b>. Once done, you’ll be able to download your certificate of completion.
				</Typography>
				<Spacer height={theme.spacing(3)} />
				<Typography variant={`medium`} component='div'>
					<b>Please note</b>: No pass requirement applies to the course.
				</Typography>

				<Spacer height={theme.spacing(4)} />
				<Button variant={`contained`} color={`secondary`} onClick={() => navigate(`/login`)}>
					Log in to access course
				</Button>
				<Spacer height={theme.spacing(2)} />
				<Button variant={`outlined`} color={`secondary`} onClick={() => navigate(`/register`)}>
					Create an account
				</Button>
			</Container>
			<Spacer height={theme.spacing(4)} />
			<Support maxWidth='sm' type='cfe' />
		</React.Fragment>
	)
}
