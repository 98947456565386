import { AxiosError, AxiosResponse } from 'axios'
import { ServerError } from 'types/common'
import { errorHandler } from './errorHandler'
import { Api } from './http'

export type IScormService = {
	getValue: (element: string, moduleName: string) => Promise<AxiosResponse>
	commit: (data: any, moduleName: string) => Promise<AxiosResponse>
}

export const useScormService = () => {
	async function getValue(element: string, moduleName: string) {
		return Api.get(`/v1/scorm/get_value/`, { params: { key: element, module_name: moduleName } })
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	async function commit(data: any, moduleName: string) {
		const payloadData = JSON.stringify(data)
		return Api.post(`/v1/scorm/commit/`, { data: payloadData, module_name: moduleName })
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	async function initData(moduleName: string) {
		return Api.get(`/v1/scorm/init_data/?module_name=${moduleName}`, null)
			.then((response) => {
				return response?.data
			})
			.catch((error: AxiosError<ServerError>) => {
				console.log(error)
				return errorHandler(error)
			})
	}

	return { getValue, commit, initData }
}
