import { pick } from 'lodash'
import { create } from 'zustand'
import { user } from '../constants/user'
import { User, UserOTPValidationType } from '../types/user'

export interface FormStore {
	formData: {
		otp: string
		email: string
	}
	contactData: UserOTPValidationType
	setStoreData: (_data: Partial<User>) => void
	resetStoreData: () => void
}

export const initialState: FormStore = {
	formData: {
		otp: '',
		email: '',
	},
	contactData: pick(user, ['email', 'contact_number', 'otp']),
	setStoreData: () => {},
	resetStoreData: () => {},
}

export const userValidateStore = create<FormStore>()((set) => ({
	...initialState,
	setStoreData: (data: any) =>
		set((state: Pick<FormStore, 'formData'>) => ({
			formData: { ...state.formData, ...data },
		})),
	setContactData: (data: any) =>
		set((state: Pick<FormStore, 'contactData'>) => ({
			contactData: { ...state.contactData, ...data },
		})),
	resetStoreData: () => set(initialState),
}))
