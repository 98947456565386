import { Checkbox, FormControlLabel } from '@mui/material'
import { getIn, useFormikContext } from 'formik'
import * as React from 'react'
import { memo } from 'react'
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md'
import { colors } from '../../../../constants'
import { InputFieldWrapper } from '../../wrapper'

interface FieldProps {
	name: string
	title?: string
	label?: string | React.ReactNode
	hint?: string
	onClick?: (e: any) => void
	disabled?: boolean
}

const Component: React.FC<FieldProps> = ({ name, label, title, hint, onClick, disabled }) => {
	const { values, setFieldValue } = useFormikContext()
	const value = getIn(values, name) ?? false
	const localLabel = { inputProps: { 'aria-label': `${label}` } }

	return (
		<InputFieldWrapper name={name} hint={hint} label={title}>
			<FormControlLabel
				disabled={disabled}
				label={label}
				control={
					<Checkbox
						onClick={onClick}
						{...localLabel}
						checked={value}
						icon={<MdCheckBoxOutlineBlank fontSize={24} color={colors.muted} className={`-cdp-ce-checkbox-input`} />}
						checkedIcon={<MdOutlineCheckBox fontSize={24} color={colors.text} />}
						onChange={(event) => {
							setFieldValue(name, event.target.checked)
						}}
					/>
				}
			/>
		</InputFieldWrapper>
	)
}

export default memo(Component)
