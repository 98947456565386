import { Typography } from '@mui/material'
import { Box } from 'components/box/box'
import { Icon } from 'components/icon'
import React from 'react'

import { colors } from '../../../../../../constants'

const styles = {
	tile: {
		display: 'flex',
		borderRadius: '4px',
		border: `1px solid ${colors.brand_graphite_13}`,
		margin: '16px 0',
	},
	tileLeft: {
		// backgroundColor: '#ff0000 !important',
		width: '72px',
		padding: '16px',
	},
	tileLeftCompleted: {},
	tileLeftCurrent: {},
	tileLeftFuture: {},
	tileRight: {
		flexGrow: 1,
	},
}

interface AssessmentTileInterface {
	completed_offline: boolean
	icon: 'quiz' | 'course'
	status: 'available' | 'unavailable' | 'in_progress' | 'completed'
	locked?: boolean
	title: string
	subtitle: string
	onStart?: () => any
	progress?: number
}

export const AssessmentTile: React.FC<AssessmentTileInterface> = (props: AssessmentTileInterface) => {
	const redStartColor = colors.brand_smile

	const getTileLeftStatusStyle = () => {
		const status = props.status

		const borderRadius = { borderRadius: '4px 0 0 4px !important' }
		const base = { ...borderRadius, backgroundColor: `${colors.brand_graphite_8} !important` }
		if (status === 'completed' || props.completed_offline) {
			return { ...base, borderLeft: '4px solid #59BF75' }
		}

		if (status === 'available' || status === 'in_progress') {
			return { ...borderRadius, borderLeft: '4px solid #F52D28' }
		}

		return { ...base, borderLeft: '4px solid #9A9595' }
	}

	return (
		<Box style={styles.tile}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					...styles.tileLeft,
					...getTileLeftStatusStyle(),
				}}>
				{props.status === 'completed' || props.completed_offline ? (
					<Icon name={'circleTick'} width={40} height={40} color='#59BF75' />
				) : (
					<Icon name={props.icon === 'quiz' ? 'qAndA' : 'bookLight'} width={40} height={40} />
				)}
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					padding: '16px 12px',
				}}
				style={styles.tileRight}>
				<Typography fontWeight={600} variant='medium' lineHeight={'21px'}>
					{props.title}
				</Typography>
				<Typography variant='body1'>
					{props.status === 'completed' || props.completed_offline ? 'Completed' : props.subtitle}
				</Typography>
				<Box>
					<Box>
						{props.icon === 'course' && (
							<Box>
								<Box>
									<Typography sx={{ color: colors.brand_graphite_48 }} variant='body1'>
										{props.status === 'completed' || props.completed_offline ? '100' : props.progress ?? '0'}%
									</Typography>
								</Box>
							</Box>
						)}
						{props.onStart &&
							!props.completed_offline &&
							!props.locked &&
							(props.status === 'available' || props.status === 'in_progress') && (
								<Box display='flex' alignItems='center' alignContent='center'>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											color: redStartColor,
											margin: '8px 0',
											cursor: 'pointer',
										}}
										onClick={() => props.onStart()}>
										{props.icon === 'quiz' && (
											<Typography variant='body1' sx={{ marginRight: '8px', color: redStartColor, fontWeight: 600 }}>
												Start assessment
											</Typography>
										)}
										{props.icon === 'course' && (
											<Typography variant='body1' sx={{ marginRight: '8px', color: redStartColor, fontWeight: 600 }}>
												{props.status === 'in_progress' ? 'Resume' : 'Start'} learning
											</Typography>
										)}

										<Icon name='caretRight' height={26} width={26} color={redStartColor} />
									</Box>
								</Box>
							)}
					</Box>
				</Box>
			</Box>
			{props.locked && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						marginRight: '8px',
					}}>
					<Icon name='lock' width={24} height={24} />
				</Box>
			)}
		</Box>
	)
}
