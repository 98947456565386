import { createContactNumberValidator } from 'utils'
import { object, string } from 'yup'
import { PASSWORD_VALIDATION_RGX } from '../../../../../constants'
import { errorMessages } from '../../../../../message/errorMessages'

export const schema = object().shape({
	first_name: string().required(errorMessages.user.FIRST_NAME),
	last_name: string().required(errorMessages.user.LAST_NAME),
	email: string().email().required(errorMessages.user.EMAIL),
	contact_number: createContactNumberValidator('contact_number', string()),
	password: string()
		.min(10, errorMessages.user.PASSWORD)
		.matches(PASSWORD_VALIDATION_RGX, errorMessages.user.PASSWORD_SPEC)
		.required(errorMessages.user.PASSWORD),
})
