import { Dialog, Typography, useMediaQuery } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import { Button } from 'components/button/button'
import { Illustration } from 'components/illustration'
import { Spacer } from 'components/layout/spacer'
import React from 'react'
import { colors } from '../../../../../../constants'

interface CompleteDialogProps {
	message: string
	open: boolean
	onClose?: () => void
}

export const CompleteDialog: React.FC<CompleteDialogProps> = ({ open, onClose, message }) => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true

	return (
		<Dialog
			open={open}
			sx={{
				'.MuiPaper-root': {
					maxHeight: '100%',
					margin: isMobile ? theme.spacing(3) : null,
				},
			}}>
			<Box padding={theme.spacing(isMobile ? 1 : 4)}>
				<Box textAlign={'center'}>
					<Illustration name={`success`} width={`50%`} />
				</Box>
				<Spacer height={theme.spacing(2)} />
				<Typography color={colors.brand_passion} variant='giant' align='center' component='div'>
					Well done
				</Typography>
				<Spacer height={theme.spacing(2)} />
				<Typography align='center' variant={isMobile ? 'medium' : 'large'} component='div'>
					{message}
				</Typography>
				<Spacer height={theme.spacing(4)} />
				<Box>
					<Button color='secondary' size='large' variant='contained' onClick={onClose}>
						Close
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}
