import { Field, FieldProps, useFormikContext } from 'formik'
import * as React from 'react'
import { memo, useEffect } from 'react'

interface FieldComponentProps {
	name: string
	value: string | number
}

const Component: React.FC<FieldComponentProps> = ({ name, value }) => {
	const { setFieldValue } = useFormikContext()

	useEffect(() => {
		if (value) {
			setFieldValue(name, value)
		}
	}, [])

	return (
		<Field name={name} value={value}>
			{/* @ts-ignore */}
			{({ field }: FieldProps) => <input type={`hidden`} {...field} />}
		</Field>
	)
}

export default memo(Component)
