export default class Storage {
	static setItem(element: string, value: any) {
		let item: any = window.localStorage.getItem('scormData')
		if (!item || item === '') {
			item = '{}'
			window.localStorage.setItem('scormData', item)
		}
		item = JSON.parse(item)
		item[element] = value
		window.localStorage.setItem('scormData', JSON.stringify(item))
	}

	static getItem(element: string) {
		let item: any = window.localStorage.getItem('scormData')
		if (!item) {
			return null
		}
		item = JSON.parse(item)
		let _return = item[element] || null
		return _return
	}

	static getAll() {
		let item = window.localStorage.getItem('scormData')
		if (!item) {
			return ''
		}
		item = JSON.parse(item)
		return item || ''
	}

	static clearAll() {
		window.localStorage.removeItem('scormData')
	}
}

export const scormModuleNames = ['financial-literacy-course']
