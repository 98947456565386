import { Collapse, Divider, List, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material'
import * as React from 'react'
import { useState } from 'react'
import { IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5'
import { sizing } from '../../constants'

interface CollapsibleProps {
	title?: string
	data: CollapsibleItemProps[]
}

interface CollapsibleItemProps {
	title: React.ReactNode
	description: React.ReactNode
}

export const CollapsibleRow: React.FC<CollapsibleItemProps> = ({ title, description }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const handleOpen = () => {
		setIsOpen(!isOpen)
	}

	return (
		<React.Fragment>
			<ListItemButton
				onClick={handleOpen}
				sx={{
					minHeight: 56,
				}}>
				<ListItemText
					primary={title}
					primaryTypographyProps={{
						fontSize: Math.round(sizing.bodyFontSize * 1.16),
						fontWeight: 600,
					}}
				/>
				{isOpen ? <IoChevronUpSharp fontSize={21} /> : <IoChevronDownSharp fontSize={21} />}
			</ListItemButton>
			<Divider />
			<Collapse in={isOpen} timeout={`auto`} unmountOnExit={false}>
				<Typography p={2}>{description}</Typography>
			</Collapse>
		</React.Fragment>
	)
}

export const Collapsible: React.FC<CollapsibleProps> = ({ data, title }) => {
	return (
		<List
			sx={{
				width: `100%`,
			}}
			component={`nav`}
			subheader={
				title && (
					<ListSubheader component={`div`}>
						<Typography variant={`h5`} fontSize={23} fontWeight={800} marginBottom={2}>
							{title}
						</Typography>
					</ListSubheader>
				)
			}>
			<Divider />
			{data.map((item: CollapsibleItemProps, i: number) => (
				<CollapsibleRow key={i + 1} title={item?.title} description={item?.description} />
			))}
		</List>
	)
}
