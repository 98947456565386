import { Box } from '@mui/material'
import React from 'react'
import { colors } from '../../../constants'
import { Spacer } from '../../layout/spacer'

const Splash: React.FC = () => {
	return (
		<Box
			sx={{
				height: '100vh',
				width: '100vw',
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: colors.bg,
				textAlign: 'center',
			}}>
			<img src='/loader-red.gif' alt='logo' width={72} />
			<Spacer height={60} />
		</Box>
	)
}

export default Splash
