import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import { Support } from 'components/support'
import * as React from 'react'
import { Row } from '../../../components/grid'
import { Icon } from '../../../components/icon'
import { Spacer } from '../../../components/layout/spacer'
import { RtwAppDownload } from '../../../components/rtw-app-download'
import { colors, sizing } from '../../../constants'

export const RegistrationFooter: React.FC = () => {
	return (
		<Box
			sx={{
				backgroundColor: colors.bg,
			}}>
			<Container
				className={`-cdp-footer-registration`}
				disableGutters
				sx={{
					padding: 0,
				}}>
				<Box
					sx={{
						backgroundColor: colors.bg,
					}}>
					<Grid container={true}>
						<Grid item={true} xs={12} md={6} padding={2} paddingTop={4}>
							<Box>
								<Typography variant={`h3`}>Already registered for ReadytoWork?</Typography>
								<Box>
									<Typography>
										You can access your Consumer Financial Education and ReadytoWork e-learning courses using the same
										login details on the Absa Digital Hub. As a registered ReadytoWork user, you can log into your
										profile to update some of your personal information in order to add Consumer Financial Education
										course to your profile.
									</Typography>
								</Box>
								<Spacer height={sizing.themeSpacing * 3} />
								<Box>
									<Row justifyContent={`flex-start`}>
										<Icon name={`consumerEducation`} width={24} height={24} />
										<Typography fontSize={16} fontWeight={800} marginLeft={1}>
											Consumer Financial Education
										</Typography>
									</Row>
									<Typography>
										Consumer Financial Education is a financial literacy initiative designed to help our users expand
										their knowledge on money matters and understanding their finances better. Create an account or login
										to access our online course for free.
									</Typography>
								</Box>
								<Spacer height={sizing.themeSpacing * 3} />
								<Divider />
								<Spacer height={sizing.themeSpacing * 3} />
								<Box>
									<Row justifyContent={`flex-start`}>
										<Icon name={`readyToWork`} width={24} height={24} />
										<Typography fontSize={16} fontWeight={800} marginLeft={1}>
											ReadytoWork
										</Typography>
									</Row>
									<Typography>
										Yesterday's skill won't cut it in tomorrow's world of work. Become future-ready with the emotional
										and digital skills you need to get ahead in the game with Absa ReadytoWork.
									</Typography>
								</Box>
							</Box>
							<Spacer height={sizing.themeSpacing * 3} />
							<RtwAppDownload />
						</Grid>
						<Grid item={true} xs={12} md={6} paddingTop={2}>
							<Support responsive={false} />
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	)
}
