import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { memo } from 'react'
import { colors } from '../../../constants'
import { initialDialogue } from '../../../context/Provider'
import { Icon } from '../../icon'

export interface DialogInterface {
	open: boolean
	title?: string
	description?: string
	setDialog: any
	onConfirm: any
	confirmTitle: string
	variant?: 'success' | 'warning' | 'error' | 'info'
	hideCancel?: boolean
}

const Component: React.FC<DialogInterface> = ({
	open,
	setDialog,
	title,
	description,
	onConfirm,
	confirmTitle,
	variant = 'info',
	hideCancel,
}) => {
	const handleClose = () => {
		setDialog(initialDialogue)
	}

	const handleConfirm = async () => {
		try {
			if (typeof onConfirm === 'function') {
				await onConfirm()
			}
		} catch (error) {
			console.error(error)
		} finally {
			setDialog(initialDialogue)
		}
	}

	const color: Record<DialogInterface['variant'], string> = {
		success: colors.brand_success,
		warning: colors.brand_warning,
		error: colors.danger,
		info: colors.danger,
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={`xs`}
			sx={{ padding: 0 }}
			PaperProps={{
				padding: 0,
			}}>
			<Box sx={{ width: 260, margin: `0 auto`, height: 42, textAlign: `center`, paddingTop: 2 }}>
				<Icon name={variant} color={color[variant]} width={40} height={40} />
			</Box>
			<DialogTitle textAlign={`center`} margin={0}>
				{title}
			</DialogTitle>
			<DialogContent>
				<Box sx={{ width: 260, margin: `0 auto`, minHeight: 90 }}>
					<DialogContentText textAlign={`center`} color={variant}>
						{description}
					</DialogContentText>
				</Box>
			</DialogContent>
			<DialogActions
				sx={{
					display: 'flex',
					justifyContent: 'center',
					backgroundColor: colors.bg,
				}}>
				{!hideCancel && (
					<Button
						variant={`outlined`}
						color={`secondary`}
						onClick={handleClose}
						sx={{
							width: 160,
						}}>
						Cancel
					</Button>
				)}
				<Button
					variant={`contained`}
					onClick={handleConfirm}
					sx={{
						width: 160,
						color: `white`,
					}}>
					{confirmTitle}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default memo(Component)
