import { Alert } from '@mui/material'
import * as React from 'react'
import { memo } from 'react'
import { Box } from '../../../components/box/box'
import { colors, sizing } from '../../../constants'
import { Icon } from '../../icon'

interface ComponentInterface {
	severity?: 'success' | 'warning' | 'error' | 'info'
	message?: string
	variant?: 'outlined' | 'standard' | 'filled'
}

export const alertIconMapping = (light = false) => {
	const color: Record<ComponentInterface['severity'], string> = light
		? {
				success: 'white',
				warning: 'white',
				error: 'white',
				info: 'white',
			}
		: {
				success: colors.brand_success,
				warning: colors.brand_warning,
				error: colors.danger,
				info: colors.brand_info,
			}

	return {
		success: <Icon name={`success`} color={color.success} width={21} height={21} />,
		warning: <Icon name={`warning`} color={color.warning} width={21} height={21} />,
		error: <Icon name={`error`} color={color.error} width={21} height={21} />,
		info: <Icon name={`info`} color={color.info} width={21} height={21} />,
	}
}

const Component: React.FC<ComponentInterface> = ({ severity = `info`, variant = `outlined`, message }) => {
	return (
		<Box marginBottom={`${sizing.themeSpacing}px`}>
			<Alert variant={variant} severity={severity} className={`-cdp-ce-alert-${severity}`} iconMapping={alertIconMapping()}>
				{message}
			</Alert>
		</Box>
	)
}

export default memo(Component)
