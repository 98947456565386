import { Component } from 'react'

export type ErrorBoundaryProps = {
	onError?: any
	errorMessage?: any
	children?: any
}

export type ErrorBoundaryStateProps = {
	error: boolean
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryStateProps> {
	state = {
		error: false,
	}

	static getDerivedStateFromError() {
		return { error: true }
	}

	componentDidCatch(error: any, errorInfo: any) {
		if (typeof this.props?.onError === 'function') {
			return this.props.onError(error, errorInfo)
		}
	}

	render() {
		if (this.state.error) {
			return this.props?.errorMessage
		} else {
			return this.props.children
		}
	}
}
