import { Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import { Spacer } from 'components/layout/spacer'
import { Support } from 'components/support'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDashboardService } from 'services/dashboard'
import { userCurrentStore } from 'store/userCurrentStore'
import { Dashboard } from 'types/dashboard'
import { UserTokenType } from 'types/user'
import { RTW_URL, sizing, TOKEN_TAG } from '../../../../../../constants'
import AppAssessmentProgress from './Component.appAssessmentProgress'
import AppAssessmentStatus from './Component.appAssessmentStatus'
import AppCard from './Component.appCard'

export default function Component() {
	const navigate = useNavigate()
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const { currentUser } = userCurrentStore()
	const tokenStore = window.localStorage.getItem(TOKEN_TAG)
	const token = tokenStore ? JSON.parse(tokenStore) : ({} as UserTokenType)

	const [dashboardSummary, setDashboardSummary] = React.useState<Dashboard | null>(null)
	const { getDashboardSummary } = useDashboardService()
	React.useEffect(() => {
		getDashboardSummary().then((data) => {
			console.log('Dashboard.data: ', data)
			setDashboardSummary(data)
		})
	}, [])

	// End of service

	const userTypeSet = new Set(currentUser.user_type_raw.split(',').map((item) => item.trim()))

	return (
		dashboardSummary && (
			<Box display='flex' flexDirection='column' minHeight={'100%'}>
				<Box flexGrow={1}>
					<Container>
						{isMobile && <Spacer height={sizing.themeSpacing * 2} />}
						<Box width='100% !important' paddingY={`${sizing.themeSpacing * (isMobile ? 2 : 2.5)}px`}>
							<Typography
								component='div'
								variant={isMobile ? 'huge' : 'giant'}
								fontWeight={isMobile ? 600 : 700}
								sx={{ paddingBottom: `${sizing.themeSpacing}px` }}
								textAlign={isMobile ? 'center' : 'start'}
								lineHeight={`${sizing.themeSpacing * (isMobile ? 4 : 5)}px`}>
								Hi {currentUser.first_name}
								<Spacer height={sizing.themeSpacing * 2} />
							</Typography>
							{
								<Typography
									variant={isMobile ? 'body1' : 'huge'}
									lineHeight={`${sizing.themeSpacing * 2}px`}
									textAlign={isMobile ? 'center' : 'start'}
									marginTop={`${sizing.themeSpacing}px`}>
									Welcome to the Absa Digital Hub
								</Typography>
							}
						</Box>
						<Spacer height={sizing.themeSpacing * 4} />
						<Box display='flex' justifyContent='center' maxWidth='1240px'>
							<Grid container spacing={`${sizing.themeSpacing * 4}px`}>
								{userTypeSet.has('cfe') && (
									<Grid item md={6} xs={12}>
										<AppCard
											title='Consumer Financial Education'
											onActionClick={() => navigate('/consumer-financial-education')}>
											{dashboardSummary.cfe?.map((item, index) => {
												return <AppAssessmentProgress key={item.id} index={index} module={item} />
											})}
										</AppCard>
									</Grid>
								)}
								{userTypeSet.has('rtw') && (
									<Grid item md={6} xs={12} display='flex' alignItems='stretch' justifyItems='stretch'>
										<AppCard
											title='ReadytoWork'
											fullWidth
											onActionClick={() =>
												window.open(
													`${RTW_URL}/?token=${btoa(
														JSON.stringify({
															access: token.access,
															refresh: token.refresh,
															confirmed: token.confirmed,
														})
													)}`,
													'_blank'
												)
											}>
											<AppAssessmentStatus rtw={dashboardSummary?.rtw} />
										</AppCard>
									</Grid>
								)}
							</Grid>
						</Box>
					</Container>
				</Box>
				<Spacer height={theme.spacing(4)} />
				<Support />
			</Box>
		)
	)
}
