import { Container, Typography } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import { Button } from 'components/button/button'
import { Icon } from 'components/icon'
import { Spacer } from 'components/layout/spacer'
import { CircularProgress } from 'components/progress'
import { Support } from 'components/support'
import { useAppContext } from 'context/Provider'
import React, { useEffect } from 'react'
import { useCFEAssessmentService } from 'services/cfe_assessment'
import { FullModuleSummary, Question } from 'types/cfe_assessment'
import { AssessmentHeader } from './Component.assessment.header'
import { AssessmentQuestion } from './Component.assessmentQuestion'
import { CompleteDialog } from './Component.completeDialog'

interface AssessmentProps {
	module: FullModuleSummary
	updateCurrentModuleIndex: (value: any) => any
}

interface QuizAnswers {
	[key: number]: {
		[key: number]: {
			question: number
			option: number
		}
	}
}

export const Assessment: React.FC<AssessmentProps> = (props) => {
	const { setSnackbar } = useAppContext()
	const {
		module: { module, quizzes },
		updateCurrentModuleIndex: setCurrentModuleIndex,
	} = props
	const { cfeBulkQuizAnswers } = useCFEAssessmentService()
	const [busy, setBusy] = React.useState(false)
	const [quizIndex, setQuizIndex] = React.useState(0)
	const [quizAnswers, setQuizAnswers] = React.useState<QuizAnswers>({})
	const [showAssessmentComplete, setShowAssessmentComplete] = React.useState(false)

	const totalQuizzes = module.quizzes.length
	const quizId = module.quizzes[quizIndex]
	const nextQuizId = quizIndex < totalQuizzes ? module.quizzes[quizIndex + 1] : null

	const quiz = quizzes[quizId]
	const nextQuiz = nextQuizId != null ? quizzes[nextQuizId] : null

	const backToModuleList = () => {
		setCurrentModuleIndex(null)
		setShowAssessmentComplete(false)
	}

	const getAnswer = (quizId: number, questionId: number): number | null => {
		const quiz = quizAnswers[quizId]
		if (!quiz) {
			return null
		}

		const answer = quizAnswers[quizId][questionId]
		if (!answer) {
			return null
		}

		return answer.option
	}

	const buildQuestions = (quizId: number, questions: Question[]) => {
		return questions.map((question, index) => {
			return (
				<Box key={question.id}>
					<Spacer height={theme.spacing(4)} />
					<AssessmentQuestion
						disabled={busy}
						question_number={index + 1}
						title={question.text}
						options={question.options}
						answer_id={getAnswer(quizId, question.id)}
						onClick={(optionId: number) => {
							markAnswer(quizId, question.id, optionId)
						}}
					/>
				</Box>
			)
		})
	}

	const quizProgress = (Object.values(quizAnswers[quiz.id] ?? {}).length / quiz.questions.length) * 100
	const canMoveOn = quiz && quiz.questions.length === Object.values(quizAnswers[quiz.id] ?? {}).length
	const handleNext = () => {
		submitQuizAnswers().then((res) => {
			if (res?.error) {
				setSnackbar({
					open: true,
					message: res?.message,
					severity: `error`,
				})
				return
			}

			if (nextQuiz) {
				setQuizIndex((prevState) => prevState + 1)
				document.body.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				})
			} else {
				setShowAssessmentComplete(true)
			}
		})
	}

	const markAnswer = (quizId: number, questionId: number, optionId: number) => {
		setQuizAnswers((prevState) => {
			return {
				...prevState,
				[quizId]: {
					...prevState[quizId],
					[questionId]: { question: questionId, option: optionId },
				},
			}
		})
	}

	const submitQuizAnswers = async () => {
		const answers = Object.values(quizAnswers[quiz.id])
		setBusy(true)
		return cfeBulkQuizAnswers(quizId, answers)
			.then((res) => {
				return res
			})
			.finally(() => {
				setBusy(false)
			})
	}

	useEffect(() => {
		const completedOffline = props.module.module.completed_offline
		const statusComplete = props.module.module.status === 'completed'
		if (completedOffline || statusComplete) {
			props.updateCurrentModuleIndex(null)
		}
	}, [])

	return (
		<React.Fragment>
			<CompleteDialog message={module.completion_message} onClose={backToModuleList} open={showAssessmentComplete} />

			<AssessmentHeader module={props.module} updateCurrentModuleIndex={props.updateCurrentModuleIndex} />
			<Container maxWidth={`xs`}>
				<Box>
					<Spacer height={theme.spacing(2)} />
					<Typography variant='huge' fontWeight={600}>
						{module.name}
					</Typography>
					<Spacer height={theme.spacing(1)} />
					<Typography variant='medium'>{quiz.description}</Typography>
					<Spacer height={theme.spacing(5)} />
					<Box>
						<CircularProgress value={quizProgress} icon={<Icon name='paper' width={20} height={20} />} />
					</Box>
					<Spacer height={theme.spacing(1)} />
					<Box>
						<Typography variant='jumbo' fontWeight={700}>
							{quiz.name}
						</Typography>
					</Box>

					<Box>{buildQuestions(quiz.id, quiz.questions)}</Box>

					<Spacer height={theme.spacing(5)} />

					<Box sx={{ display: 'flex', gap: '8px' }}>
						<Button disabled={busy} color='secondary' variant='outlined' onClick={() => setCurrentModuleIndex(null)}>
							Cancel
						</Button>
						<Button color='secondary' variant='contained' disabled={busy || !canMoveOn} onClick={handleNext}>
							{nextQuiz ? 'Next' : 'Complete'}
						</Button>
					</Box>
				</Box>
			</Container>
			<Spacer height={theme.spacing(4)} />
			<Support />
		</React.Fragment>
	)
}
