import { Container, Typography } from '@mui/material'
import { theme } from 'assets/material/theme'
import { Box } from 'components/box/box'
import { Button } from 'components/button/button'
import { Row } from 'components/grid'
import { Illustration } from 'components/illustration'
import { Spacer } from 'components/layout/spacer'
import { Support } from 'components/support'
import { useAppContext } from 'context/Provider'
import React, { useState } from 'react'
import { useCFEAssessmentService } from 'services/cfe_assessment'
import { userCurrentStore } from 'store/userCurrentStore'
import { FullModuleSummary, MODULE_COURSE_NAME } from 'types/cfe_assessment'
import { scrollToTop } from 'utils'
import { AssessmentTile } from './Component.assessmentTile'

interface ModuleListProps {
	modules?: FullModuleSummary[]
	updateCurrentModuleIndex: (value: number) => any
}

export const ModuleList: React.FC<ModuleListProps> = (props) => {
	const { currentUser } = userCurrentStore()
	const { setSnackbar } = useAppContext()
	const { cfeDownloadCertificate } = useCFEAssessmentService()
	const [downloading, setDownloading] = useState(false)

	const buildModules = (summaries: FullModuleSummary[]) => {
		return summaries.map((summary, index) => {
			return (
				<AssessmentTile
					key={summary.module.id}
					completed_offline={summary.module.completed_offline}
					icon={summary.module.module_type}
					title={summary.module.name}
					subtitle={summary.module.name}
					status={summary.module.status}
					locked={summary.module.locked}
					onStart={() => props.updateCurrentModuleIndex(index)}
					progress={summary.module.module_type === 'course' ? summary.module.course_status[MODULE_COURSE_NAME] ?? 0 : 0}
				/>
			)
		})
	}

	// const allCoursesComplete =
	let allComplete = false
	if (props.modules) {
		allComplete = true
		for (const summary of props.modules) {
			allComplete = allComplete && summary.module.status === 'completed'
		}
	}

	const downloadFile = () => {
		setDownloading(true)
		cfeDownloadCertificate(currentUser)
			.then((res) => {
				if (res?.error) {
					setSnackbar({
						open: true,
						message: res?.message,
						severity: `error`,
					})
				}
			})
			.finally(() => setDownloading(false))
	}

	React.useEffect(() => {
		scrollToTop()
	}, [])

	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<Spacer height={theme.spacing(3)} />
				<Row justifyContent={`center`}>
					<Box maxWidth={220}>
						<Illustration name={`retail`} width={`100%`} />
					</Box>
				</Row>
				<Spacer height={theme.spacing(4)} />
				<Typography variant={`h3`} fontWeight={600} textAlign={`center`}>
					Consumer Financial Education
				</Typography>
				<Typography variant={`medium`} textAlign={`center`} component='div'>
					Consumer Financial Education is a financial initiative designed to help our users expand their knowledge on financial
					management.
				</Typography>
				<Spacer height={theme.spacing(4)} />

				<Box>{props.modules && buildModules(props.modules)}</Box>

				<Spacer height={theme.spacing(2)} />
				{allComplete && (
					<Button
						disabled={!allComplete || downloading}
						isLoading={downloading}
						onClick={downloadFile}
						variant={`contained`}
						color={`secondary`}>
						Download Certificate
					</Button>
				)}
			</Container>
			<Spacer height={theme.spacing(1.5)} />
			<Support maxWidth='sm' type='cfe' />
		</React.Fragment>
	)
}
