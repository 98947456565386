import { useMediaQuery } from '@mui/material'
import { Box } from 'components/box/box'
import { Button } from 'components/button/button'
import { useAppContext } from 'context/Provider'
import SCORMAdapter, { completeStatusKey } from 'helpers/scorm_api_adapter'
import Storage from 'helpers/scorm_storage'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModuleService } from 'services/module'
import { useScormService } from 'services/scorm'
import { userCurrentStore } from 'store/userCurrentStore'
import { FullModuleSummary, MODULE_COURSE_NAME } from 'types/cfe_assessment'
import { AssessmentHeader } from '../Component.assessment.header'
import { CompleteDialog } from '../Component.completeDialog'
import ScormModuleView from './Component.scormModuleView'

const COMPLETION_CHECK_INTERVAL_MS = 1000

interface ScormAssessmentProps {
	module: FullModuleSummary
	updateCurrentModuleIndex: (value: any) => any
}

const ScormAssessment: React.FC<ScormAssessmentProps> = (props) => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const navigate = useNavigate()
	const { currentUser } = userCurrentStore()
	const moduleService = useModuleService()
	const [showCompletionDialog, setshowCompletionDialog] = useState(false)
	const [completed, setCompleted] = useState(false)
	const [committing, setCommitting] = useState(false)
	const scormService = useScormService()
	const { setDialog, setSnackbar } = useAppContext()

	const handleNext = () => {
		setshowCompletionDialog(true)
	}

	const completeOffline = () => {
		const markCourseAsCompleted = () => {
			// const
			const mod = props.module
			moduleService
				.completeScormOffline(mod.module.id, {
					name: mod.module.name,
					completetion_message: mod.module.completion_message,
				})
				.then((res) => {
					if (res?.error) {
						setSnackbar({
							open: true,
							message: res?.message,
							severity: `error`,
						})
					} else {
						navigate(`/resources`)
					}
				})
		}

		setDialog({
			open: true,
			title: `Complete Offline`,
			description: `Please ensure you've downloaded the course content from the Resource page to your device.  By selecting "Complete", you confirm that you've downloaded and reviewed the course materials offline.`,
			onConfirm: () => markCourseAsCompleted(),
			setDialog,
			confirmTitle: 'Complete',
			variant: 'info',
		})
	}

	const backToModuleList = async (commited?: boolean) => {
		if (commited || committing) {
			props.updateCurrentModuleIndex(null)
			return
		}

		try {
			setCommitting(true)
			const scormAdapter = new SCORMAdapter(MODULE_COURSE_NAME, currentUser, scormService, null)
			await scormAdapter.LMSFinish()
			props.updateCurrentModuleIndex(null)
		} finally {
			setCommitting(false)
		}
	}

	useEffect(() => {
		const completedOffline = props.module.module.completed_offline
		const statusComplete = props.module.module.status === 'completed'
		if (completedOffline || statusComplete) {
			props.updateCurrentModuleIndex(null)
		}
	}, [])

	useEffect(() => {
		let timer = setInterval(() => {
			const data: any = Storage.getAll()
			const status = data[completeStatusKey]
			if (status === 'completed') {
				setCompleted(true)
				clearTimer()
			}
		}, COMPLETION_CHECK_INTERVAL_MS)

		const clearTimer = () => {
			if (timer === null) {
				return
			}
			clearInterval(timer)
			timer = null
		}

		return () => {
			clearTimer()
		}
	}, [])

	return (
		<React.Fragment>
			<CompleteDialog message={props.module.module.completion_message} onClose={backToModuleList} open={showCompletionDialog} />
			<Box sx={{ height: isMobile ? '100vh' : '100%' }}>
				<Box display='flex' flexDirection='column' minHeight='calc(100% - 63px)'>
					<AssessmentHeader
						{...props}
						action={
							<Box sx={{ maxWidth: '10em' }}>
								<Button
									color='secondary'
									variant='contained'
									size='small'
									disabled={showCompletionDialog || committing}
									onClick={completed ? handleNext : completeOffline}>
									{completed ? 'Complete' : 'Complete Offline'}
								</Button>
							</Box>
						}
					/>
					<Box flexGrow={1} display={'flex'} justifyContent='center'>
						<Box maxWidth='lg'>
							<ScormModuleView backToModuleList={backToModuleList} moduleName={MODULE_COURSE_NAME} />
						</Box>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	)
}

export default ScormAssessment
