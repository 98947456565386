import { BaseTextFieldProps, InputProps as InputPropsInterface, MenuItem, Select } from '@mui/material'
import clsx from 'clsx'
import { getIn, useFormikContext } from 'formik'
import * as React from 'react'
import { memo } from 'react'
import { sizing } from '../../../../constants'
import { Option } from '../../../../types/common'
import { IconButton } from '../../../button/icon-button'
import { Icon } from '../../../icon'
import { InputFieldWrapper } from '../../wrapper'

interface FieldProps extends BaseTextFieldProps {
	name: string
	label?: React.ReactNode | string
	placeholder?: string
	hint?: React.ReactNode | string
	InputProps?: InputPropsInterface
	options: Option[]
	plain?: boolean
	disabled?: boolean
}

export const Component: React.FC<FieldProps> = ({ name, label, hint, placeholder, options, plain, disabled }) => {
	const { values, errors, touched, setFieldValue } = useFormikContext()
	const value = getIn(values, name) ?? ''
	const error = getIn(errors, name)
	const touch = getIn(touched, name)
	const hasError = error && touch

	function handleChange(event: any) {
		setFieldValue(name, event.target.value)
	}

	return (
		<InputFieldWrapper name={name} label={label} hint={hint} plain={plain}>
			<Select
				disabled={disabled}
				name={name}
				value={value}
				displayEmpty={true}
				placeholder={placeholder}
				autoComplete={`off`}
				onChange={handleChange}
				fullWidth={true}
				className={clsx(`-cdp-ce-select-input`, hasError ? `-error-placeholder` : !value && `-active-placeholder`)}
				renderValue={(selectedValue) =>
					selectedValue === ''
						? placeholder ?? label
						: options.find((option) => option.value === selectedValue)?.label || 'Select'
				}
				IconComponent={(props) => {
					return (
						<IconButton
							{...props}
							sx={{
								padding: 0,
								marginRight: `${sizing.themeSpacing}px`,
							}}>
							<Icon name={`caretDown`} width={`14px`} height={`14px`} />
						</IconButton>
					)
				}}
				sx={{ '& .MuiSelect-icon': { right: '12px' } }}
				inputProps={{
					style: {
						textAlign: `left`,
						borderRadius: 8,
					},
				}}>
				<MenuItem value='' disabled={true} className={`select-placeholder`}>
					{placeholder ?? label}
				</MenuItem>
				{options.length > 0 &&
					options.map((option, i) => {
						return (
							<MenuItem key={i + 1} value={option.value}>
								{option.label}
							</MenuItem>
						)
					})}
			</Select>
		</InputFieldWrapper>
	)
}

export default memo(Component)
