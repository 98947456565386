import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { colors } from '../../constants'
import { Box } from '../box/box'
import { Row } from '../grid'
import { Spacer } from '../layout/spacer'
import { Link } from '../link'

const links = [
	{
		label: 'Privacy Statement',
		url: 'https://www.absa.co.za/legal-and-compliance/privacy-statement/',
	},
	{
		label: 'Terms of Use',
		url: 'https://www.absa.co.za/legal-and-compliance/terms-of-use/',
	},
	{
		label: 'Banking Regulations',
		url: 'https://www.absa.co.za/about-us/absa-bank/corporate-information/',
	},
	{
		label: 'Security center',
		url: 'https://www.absa.co.za/self-service/safety-security/',
	},
]

export const Footer: React.FC = () => {
	return (
		<Box component={`footer`} className='footer' fullWidth={true} maxWidth={`100vw`} sx={{ overflowX: `hidden` }}>
			<Spacer height={60} />
			<Typography textAlign={`center`} marginBottom={2}>
				&copy; Copyright. Absa Bank Limited. Registration Number: 1986/004794/06 Authorised financial services and registered credit
				provider NCRCP7
			</Typography>
			<Box textAlign={`center`}>
				<Stack spacing={{ xs: 1 }} direction={`row`} justifyContent={`center`} useFlexGap flexWrap={`wrap`}>
					{links.map((link, i) => (
						<React.Fragment key={i + 1}>
							<Link
								to={link.url}
								target={`_blank`}
								rel={`noreferrer`}
								text={link.label}
								sx={{
									display: `inline-block`,
									textDecoration: `none`,
								}}
							/>
							{links.length !== i + 1 && <Typography color={colors.brand_hope}>|</Typography>}
						</React.Fragment>
					))}
				</Stack>
			</Box>
			<Spacer height={42} />
			<Row height={8} minHeight={8}>
				<Box display={`flex`} flexGrow={1} height={8} backgroundColor={colors.brand_passion} />
				<Box display={`flex`} flexGrow={1} height={8} backgroundColor={colors.brand_power} />
				<Box display={`flex`} flexGrow={1} height={8} backgroundColor={colors.brand_hope} />
			</Row>
		</Box>
	)
}
