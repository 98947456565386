import saIdParser from 'south-african-id-parser'
import { ValidationError, bool, object, string } from 'yup'
import { errorMessages } from '../../../../../message/errorMessages'

export const schema = object().shape({
	address: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.ADDRESS),
		otherwise: (schema) => schema.nullable(),
	}),
	location: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		otherwise: (schema) => schema.nullable(),
	}),
	municipality: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.MUNICIPALITY),
		otherwise: (schema) => schema.nullable(),
	}),
	town: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.TOWN),
		otherwise: (schema) => schema.nullable(),
	}),
	state: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.STATE),
		otherwise: (schema) => schema.nullable(),
	}),
	id_number: string().when(['country_citizenship'], {
		is: (country: string) => country !== `South Africa`,
		then: (schema) => schema.nullable(),
		otherwise: (schema) =>
			schema.nullable().test('id-validation', errorMessages.user.ID_NUMBER, (value) => {
				try {
					const isValid = value && saIdParser.validate(value)
					if (!isValid) {
						throw new ValidationError(errorMessages.user.ID_NUMBER, value, 'id_number')
					}
					return true
				} catch (error: any) {
					throw new ValidationError(error.message, value, 'id_number')
				}
			}),
	}),
	country: string().notOneOf(['Other'], errorMessages.user.COUNTRY_OTHER).required(errorMessages.user.COUNTRY_RESIDENCE),
	country_citizenship: string().notOneOf(['Other'], errorMessages.user.COUNTRY_OTHER).required(errorMessages.user.COUNTRY_CITIZENSHIP),
	income_over_250k: bool().when(['country', 'country_citizenship'], {
		is: (country: string, countryCitizenship: string) => country === `South Africa` && countryCitizenship === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.INCOME),
		otherwise: (schema) => schema.nullable(),
	}),
})
