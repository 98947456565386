import { Breakpoint, Container, Grid, Typography } from '@mui/material'
import { Box } from 'components/box/box'
import { Collapsible } from 'components/collapsible/collapsible'
import { support } from 'constants/options'
import React from 'react'
import { colors } from '../../../constants'

interface SupportProps {
	maxWidth?: Breakpoint
	type?: 'generic' | 'cfe'
	responsive?: boolean
	alwaysExpanded?: boolean
	backgroundColor?: string
}

const Support: React.FC<SupportProps> = (props) => {
	const [showingMore, setShowingMore] = React.useState(false)
	const { maxWidth = 'md', type = 'generic', responsive = true, alwaysExpanded = false, backgroundColor } = props

	let items = support.filter((item) => {
		const modes = new Set(item.modes)
		return modes.has(type)
	})
	if (!alwaysExpanded && type === 'cfe' && !showingMore && items.length > 4) {
		items = items.slice(0, 4)
	}

	return (
		<Box backgroundColor={backgroundColor ?? colors.bg}>
			<Container maxWidth={maxWidth} sx={{ backgroundColor: backgroundColor ?? colors.bg }}>
				{responsive ? (
					<Grid item={true} xs={12} md={6} paddingTop={2}>
						<Typography variant={`h3`} marginLeft={2}>
							FAQs
						</Typography>
						<Collapsible data={items} />
					</Grid>
				) : (
					<Grid item={true} paddingTop={2}>
						<Typography variant={`h3`} marginLeft={2}>
							FAQs
						</Typography>
						<Collapsible data={items} />
					</Grid>
				)}

				{!alwaysExpanded && type === 'cfe' && (
					<Box onClick={() => setShowingMore(!showingMore)}>
						<Typography sx={{ cursor: 'pointer' }} align='center' color='secondary'>
							{showingMore ? '- Show less' : '+ Show more'}
						</Typography>
					</Box>
				)}
			</Container>
		</Box>
	)
}

export default Support
