import { object, ref, string } from 'yup'
import { PASSWORD_VALIDATION_RGX } from '../../../../../constants'
import { errorMessages } from '../../../../../message/errorMessages'

export const schema = object().shape({
	password: string()
		.min(10, errorMessages.user.PASSWORD)
		.matches(PASSWORD_VALIDATION_RGX, errorMessages.user.PASSWORD_SPEC)
		.required(errorMessages.user.PASSWORD),
	confirmPassword: string().oneOf([ref('password'), null], errorMessages.user.PASSWORD_MATCH),
})
