import { Container } from '@mui/material'
import React from 'react'
import { FormUpdateDetails } from '../../components/form-controlled/account/update/form'
import { RegistrationFooter } from '../auth/components/register-footer'

export default function PageAccountUpdateDetails() {
	return (
		<React.Fragment>
			<Container maxWidth={`xs`}>
				<FormUpdateDetails />
			</Container>
			<RegistrationFooter />
		</React.Fragment>
	)
}
