import { sizing } from '../../constants'
import { Box, BoxProps } from '@mui/material'
import * as React from 'react'

type RowProps = BoxProps & {
	center?: boolean
}

export const Spacer: React.FC<RowProps> = ({ height, ...props }) => {
	return (
		<Box width={`100%`} height={height ?? sizing.themeSpacing} {...props} />
	)
}
