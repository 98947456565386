import { AxiosError } from 'axios'
import { __DEV__ } from '../constants'
import { ServerError } from '../types/common'

export function errorHandler(error: AxiosError<ServerError, any> | undefined, message?: string) {
	if (!error) {
		return error
	}

	if (__DEV__) {
		console.error(error)
	}

	let errorMessage: string = ``
	let errorMessageFieldNames: string = ``

	/**
	 *  Check if error data is a string
	 */
	try {
		if (typeof error?.response?.data === `string`) {
			errorMessage = error?.response?.data
		}
	} catch (error) {
		console.trace(error)
	}

	/**
	 *  Check if error data is an object of
	 */
	try {
		if (!errorMessage) {
			errorMessage = Object.values(error?.response?.data)[0][0]
		}
	} catch (error) {
		console.trace(error)
	}

	/**
	 *  Check if error data is an object of
	 */
	try {
		// @ts-ignore
		errorMessageFieldNames = typeof error.response.data !== `string` ? Object.keys(error.response.data) : ``
	} catch (error) {
		console.trace(error)
	}

	if (errorMessage) {
		return {
			// @ts-ignore
			error: error.response?.data?.code ?? error?.response.status,
			message: message ?? errorMessage,
			errorMessageFieldNames,
		}
	} else if (error.response) {
		return {
			error: error.response?.data?.code ?? error.response.status,
			message: message ?? error.response.data.message ?? error.response.statusText,
			errorMessageFieldNames,
		}
	} else if (error.request) {
		return {
			error: 500,
			message: message ?? `Request was made but no response received`,
			errorMessageFieldNames,
		}
	} else {
		return {
			error: 500,
			message: message ?? `Something else happened while setting up the request`,
			errorMessageFieldNames,
		}
	}
}
