import { getIn, useFormikContext } from 'formik'
import { MuiOtpInput } from 'mui-one-time-password-input'
import * as React from 'react'
import { memo } from 'react'
import { sizing } from '../../../../constants'
import { Box } from '../../../box/box'
import { InputFieldWrapper } from '../../wrapper'

interface FieldProps {
	name: string
	label?: React.ReactNode | string
	hint?: React.ReactNode | string
	length?: number
	center?: boolean
	autoFocus?: boolean
}

const Component: React.FC<FieldProps> = ({ name, label, hint, length = 5, center = true, autoFocus = true }) => {
	const { values, errors, touched, setFieldValue } = useFormikContext()
	const value = getIn(values, name)
	const touch = getIn(touched, name)
	const error = touch && getIn(errors, name)

	return (
		<InputFieldWrapper name={name} hint={hint} label={label} errorOccurred={error} center={center}>
			<Box display={`inline-block`} margin={`0 auto`} maxWidth={`${(sizing.inputHeight + sizing.themeSpacing) * length}px`}>
				<MuiOtpInput
					value={value}
					autoFocus={autoFocus}
					length={length}
					gap={`${sizing.themeSpacing}px`}
					TextFieldsProps={{
						placeholder: `*`,
						name,
						error: !!error,
						className: `-cdp-ce-otp-input`,
						sx: {
							width: sizing.inputHeight + sizing.themeSpacing,
						},
					}}
					onChange={(otp) => {
						setFieldValue(name, otp)
					}}
				/>
			</Box>
		</InputFieldWrapper>
	)
}

export default memo(Component)
