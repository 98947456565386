import { Guest } from 'components/views/single/dashboard/guest'
import { Member } from 'components/views/single/dashboard/member'
import { useAppContext } from 'context/Provider'
import React, { useEffect } from 'react'
import { userCurrentStore } from 'store/userCurrentStore'

export default function PageHome() {
	const { setSnackbar } = useAppContext()
	const { currentUser } = userCurrentStore()

	useEffect(() => {
		// Hack: Need to find a better approach supporting other pages
		const profileDeleted = localStorage.getItem('profile-deleted')
		if (profileDeleted === 'true') {
			localStorage.removeItem('profile-deleted')
			setSnackbar({
				open: true,
				message: 'Profile successfully deleted',
				severity: `success`,
				vertical: 'top',
				horizontal: 'center',
			})
		}
	}, [])

	return <React.Fragment>{currentUser ? <Member /> : <Guest />}</React.Fragment>
}
