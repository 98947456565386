import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { getIn, useFormikContext } from 'formik'
import * as React from 'react'
import { memo } from 'react'
import { colors, sizing } from '../../../../constants'
import { Spacer } from '../../../layout/spacer'
import { InputFieldWrapper } from '../../wrapper'

interface Option {
	value: string
	label: string
}

interface FieldProps {
	name: string
	label?: React.ReactNode | string | any
	hint?: React.ReactNode | string
	options: Option[]
	disabled?: boolean
}

const Component: React.FC<FieldProps> = ({ name, hint, label, options, disabled }) => {
	const { values, errors, touched, setFieldValue } = useFormikContext()
	const error = getIn(errors, name)
	const touch = getIn(touched, name)
	const value = getIn(values, name) ?? ''

	const getColor = (o: Option) => {
		if (error && touch) {
			return colors.danger
		}

		if (String(o.value) === String(value)) {
			if (disabled) {
				return colors.text
			}
			return colors.brand_hope
		}

		return colors.muted
	}

	return (
		<InputFieldWrapper name={name} hint={hint} label={label}>
			<Spacer height={sizing.themeSpacing / 2} />
			<RadioGroup aria-label={label} name={name} value={value} onChange={(event) => setFieldValue(name, event.target.value)}>
				{options.map((o: Option) => (
					<FormControlLabel
						key={o.value}
						value={o.value}
						disabled={disabled}
						control={
							<Radio
								disabled={disabled}
								sx={{
									'.MuiSvgIcon-root': {
										color: getColor(o),
									},
								}}
							/>
						}
						label={<Typography color={error && touch ? colors.danger : colors.text}>{o.label}</Typography>}
					/>
				))}
			</RadioGroup>
		</InputFieldWrapper>
	)
}

export default memo(Component)
