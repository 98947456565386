import { createContactNumberValidator } from 'utils'
import { bool, object, string } from 'yup'
import { errorMessages } from '../../../../../message/errorMessages'

export const schema = object().shape({
	first_name: string().required(errorMessages.user.FIRST_NAME),
	last_name: string().required(errorMessages.user.LAST_NAME),
	address: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.ADDRESS),
		otherwise: (schema) => schema.nullable(),
	}),
	location: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		otherwise: (schema) => schema.nullable(),
	}),
	municipality: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.MUNICIPALITY),
		otherwise: (schema) => schema.nullable(),
	}),
	town: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.TOWN),
		otherwise: (schema) => schema.nullable(),
	}),
	state: string().when(['country'], {
		is: (country: string) => country === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.STATE),
		otherwise: (schema) => schema.nullable(),
	}),
	country: string().notOneOf(['Other'], errorMessages.user.COUNTRY_OTHER).required(errorMessages.user.COUNTRY_RESIDENCE),
	contact_number: createContactNumberValidator('contact_number', string()),
	income_over_250k: bool().when(['country', 'country_citizenship'], {
		is: (country: string, countryCitizenship: string) => country === `South Africa` && countryCitizenship === `South Africa`,
		then: (schema) => schema.required(errorMessages.user.INCOME),
		otherwise: (schema) => schema.nullable(),
	}),
})
