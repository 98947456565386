export interface ColorTypes {
	bg: string
	bgSurface: string
	text: string
	textDark: string
	textHelper: string
	disabled: string
	primary: string
	secondary: string
	muted: string
	mutedLight: string
	danger: string
	divider: string
	black: string
	white: string
	grey: string
	/**
	 * absa
	 */
	brand_surprise: string
	brand_calm: string
	brand_care: string
	brand_smile: string
	brand_luxury: string
	brand_depth: string
	brand_energy: string
	brand_prepared: string
	brand_agile: string
	brand_passion: string
	brand_warmth: string
	brand_human: string
	brand_grounded: string
	brand_info: string
	brand_error: string
	brand_warning: string
	brand_success: string
	brand_graphite_100: string
	brand_graphite_82: string
	brand_graphite_64: string
	brand_graphite_48: string
	brand_graphite_35: string
	brand_graphite_25: string
	brand_graphite_19: string
	brand_graphite_13: string
	brand_graphite_8: string
	brand_graphite_5: string
	brand_graphite_3: string

	/**
	 * New markerting
	 */
	// brand_passion: string
	brand_power: string
	brand_hope: string
	brand_inspire: string
	// brand_energy: string
	brand_uplift: string
	brand_enrich: string
	brand_serene: string

	brand_natural_1: string
	brand_natural_2: string
	brand_natural_3: string
	brand_natural_4: string
	brand_natural_5: string
	brand_natural_6: string
	brand_natural_7: string

	brand_grass: string
	brand_moss: string
	brand_sky: string
	brand_navy: string

	brand_grey_1: string
	brand_grey_2: string
	brand_grey_3: string
	brand_grey_4: string
	brand_grey_5: string
	brand_grey_6: string
	brand_grey_7: string
}

const colors: ColorTypes = {
	bg: '#f8f8f8',
	bgSurface: '#ffffff',
	text: '#2D2323',
	textDark: '#3f3f3f',
	textHelper: '#787272',
	disabled: 'rgb(82,74,74)',
	primary: '#aa052d',
	secondary: '#95052A', // brand_hope value
	muted: 'rgb(230,230,230)',
	mutedLight: 'rgb(230,230,230)',
	danger: '#f60e08',
	divider: '#CAC7C7',
	black: '#000000',
	white: '#ffffff',
	grey: '#808080',
	/**
	 * absa
	 */
	brand_surprise: '#af144b',
	brand_calm: '#870a3c',
	brand_care: '#f05a7d',
	brand_smile: '#f0325a',
	brand_luxury: '#640032',
	brand_depth: '#500a28',
	brand_energy: '#ff780f',
	brand_prepared: '#fa551e',
	brand_agile: '#f52d28',
	brand_passion: '#dc0032',
	brand_warmth: '#be0028',
	brand_human: '#aa052d',
	brand_grounded: '#960528',
	brand_info: '#00a0ff',
	brand_error: '#f60e08',
	brand_warning: '#ff780f',
	brand_success: '#31540D',
	brand_graphite_100: '#2d2323',
	brand_graphite_82: '#524a4a',
	brand_graphite_64: '#787272',
	brand_graphite_48: '#9a9595',
	brand_graphite_35: '#b5b2b2',
	brand_graphite_25: '#cac7c7',
	brand_graphite_19: '#d7d5d5',
	brand_graphite_13: '#e3e2e2',
	brand_graphite_8: '#eeeded',
	brand_graphite_5: '#f4f3f3',
	brand_graphite_3: '#f9f8f8',

	/**
	 * New markerting
	 */
	// brand_passion: '#DC0037',
	brand_power: '#B50232',
	brand_hope: '#95052A',
	brand_inspire: '#77021E',
	// brand_energy: '#FF780F',
	brand_uplift: '#F93F24',
	brand_enrich: '#131010',
	brand_serene: '#FFFFFF',

	brand_natural_1: '#6A3113',
	brand_natural_2: '#904420',
	brand_natural_3: '#BF642E',
	brand_natural_4: '#D68551',
	brand_natural_5: '#EC9D63',
	brand_natural_6: '#EDAD7F',
	brand_natural_7: '#F0C8A5',

	brand_grass: '#548C24',
	brand_moss: '#00635F',
	brand_sky: '#78BBFA',
	brand_navy: '#004491',

	brand_grey_1: '#ECE9E9',
	brand_grey_2: '#D9D9D9',
	brand_grey_3: '#BDBCBC',
	brand_grey_4: '#A19F9F',
	brand_grey_5: '#7B7979',
	brand_grey_6: '#565252',
	brand_grey_7: '#3A3535',
}

export default colors
