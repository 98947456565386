import { Grid } from '@mui/material'
import { Formik } from 'formik'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { userCurrentStore } from 'store/userCurrentStore'
import { useAppContext } from '../../../context/Provider'
import { errorMessages } from '../../../message/errorMessages'
import { useAuthService } from '../../../services/user'
import { userValidateStore } from '../../../store/userValidateStore'
import { maskContactNumber, maskEmail } from '../../../utils'
import { Button } from '../../button/button'
import { Form } from '../../form-controlled-field/form'
import { HiddenTextField } from '../../form-controlled-field/hidden'
import { Otp } from '../../form-controlled-field/otp'
import { FormTitle } from '../../form-controlled-field/title'
import { Spacer } from '../../layout/spacer'
import { schema } from './src/schema'

interface FormAuthValidateProps {
	successRedirectUrl?: any
}

export const FormAuthValidate: React.FC<FormAuthValidateProps> = ({ successRedirectUrl = `/` }) => {
	const [isResending, setIsResending] = useState(false)
	const { userOtpValidate, userOtpRequest, userUpdateProfile } = useAuthService()
	const navigate = useNavigate()
	const { currentUser } = userCurrentStore()
	const { setGlobalLoader, setSnackbar } = useAppContext()
	const { formData } = userValidateStore()
	const { state } = useLocation()

	async function resendOtp() {
		setIsResending(true)
		userOtpRequest({
			...state,
		})
			.then((res) => {
				if (res.error) {
					setSnackbar({
						open: true,
						message: res.error?.message,
						severity: `error`,
					})
				} else {
					setSnackbar({
						open: true,
						message: res.message,
						severity: `success`,
					})
				}
			})
			.finally(() => {
				setIsResending(false)
			})
	}

	useEffect(() => {
		if (!state?.email && !state?.contact_number && !state?.user_id) {
			navigate(-1)
		}
	}, [])

	let message: string = ``
	if (state?.email) {
		message = `We sent you a one-time pin to your email address ${maskEmail(state?.email)}`
	} else if (state?.contact_number) {
		message = `We sent you a one-time pin to your phone number ${maskContactNumber(state?.contact_number)}`
		delete formData['email'] // Can not exist with mobile_number
	} else {
		message = `We sent you a one-time pin to your account`
	}

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={formData}
				validationSchema={schema}
				onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
					const remoteErrorSet = new Set([400, 403])

					setGlobalLoader(true)
					userOtpValidate({
						...values,
						...state,
					}).then(async (res) => {
						if (res.error) {
							setGlobalLoader(false)
							setStatus({ error: res.message })
							setFieldError(`otp`, remoteErrorSet.has(res.error) ? res.message : errorMessages.user.OTP)
							setSubmitting(false)
							setSnackbar({
								open: true,
								message: res.message,
								severity: `error`,
							})
						} else {
							if (state?.values && state?.id) {
								userUpdateProfile(
									{
										...state.values,
									},
									state.id
								).then(async (res: any) => {
									if (res?.error) {
										setGlobalLoader(false)
										setSubmitting(false)
										setStatus({
											error: res?.message,
										})
										setSnackbar({
											open: true,
											message: res?.message,
											severity: `error`,
										})
										return
									} else {
										setStatus({
											success: res?.message,
										})
									}
								})
							}

							setGlobalLoader(false)
							setStatus({ success: res?.message })
							setSnackbar({
								open: true,
								message: res?.message,
								severity: `success`,
							})
							setSubmitting(false)
							navigate(currentUser ? successRedirectUrl : '/login')
						}
					})
				}}>
				{({ status, isSubmitting }) => {
					return (
						<React.Fragment>
							<FormTitle title={`One-time pin (OTP)`} description={message} status={status} />
							<Form>
								<Otp name={`otp`} />
								<HiddenTextField name={`email`} value={state?.email} />
								<Spacer height={60} />
								<Grid container={true} spacing={1}>
									<Grid item={true} xs={6}>
										<Button
											type={`button`}
											variant={`outlined`}
											color={`secondary`}
											isLoading={isResending}
											onClick={resendOtp}>
											Resend OTP
										</Button>
									</Grid>
									<Grid item={true} xs={6}>
										<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
											Next
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
