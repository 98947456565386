import { Box, Container, Typography } from '@mui/material'
import { startCase } from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../../../../assets/material/theme'
import { useAppContext } from '../../../../../context/Provider'
import { useAuthService } from '../../../../../services/user'
import { userCurrentStore } from '../../../../../store/userCurrentStore'
import { IconName } from '../../../../../types/common'
import { User } from '../../../../../types/user'
import { sleep } from '../../../../../utils'
import { Button } from '../../../../button/button'
import { Column, Row } from '../../../../grid'
import { Icon } from '../../../../icon'
import { Spacer } from '../../../../layout/spacer'
import { Link } from '../../../../link'
import { Splash } from '../../../../splash'

interface ComponentProps {
	user?: User
}

const Section: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return <Box marginBottom={theme.spacing(5)}>{children}</Box>
}

const SectionTitle: React.FC<{ title: string; icon: IconName }> = ({ title, icon }) => {
	return (
		<Row justifyContent={`flex-start`} marginBottom={theme.spacing(3)}>
			{icon && <Icon name={icon} width={24} height={24} />}
			<Typography variant={`jumbo`} fontWeight={700} marginLeft={theme.spacing(1)}>
				{title}
			</Typography>
		</Row>
	)
}

const SectionItem: React.FC<{ label: string; value: string; linkUrl?: string; linkTitle?: string }> = ({
	label,
	value,
	linkUrl,
	linkTitle,
}) => {
	return (
		<Column justifyContent={`flex-start`} marginBottom={theme.spacing(2)}>
			<Typography variant={`medium`} fontWeight={600}>
				{label}
			</Typography>
			<Typography variant={`normal`} fontWeight={400}>
				{value}
			</Typography>
			{linkTitle && linkUrl && <Link text={linkTitle} to={linkUrl} />}
		</Column>
	)
}

const Component: React.FC<ComponentProps> = () => {
	const { setDialog } = useAppContext()
	const navigate = useNavigate()
	const { userDestroy, userLogout } = useAuthService()
	const [isDestroying, setIsDestroying] = useState(false)

	const { currentUser } = userCurrentStore()

	const {
		first_name,
		last_name,
		email,
		contact_number,
		gender,
		dob,
		country,
		country_citizenship,
		id_number,
		municipality,
		income_over_250k,
		race,
	} = currentUser ?? {}
	const income = income_over_250k ? `More` : `less`
	const southAfrican = country_citizenship === `South Africa`

	async function deleteAccount() {
		try {
			setIsDestroying(true)
			await userDestroy()

			// Hack: Need to find a better way
			localStorage.setItem('profile-deleted', 'true')

			await sleep(100)
			await userLogout()
		} catch (error) {
			setIsDestroying(false)
		} finally {
			navigate(`/`)
		}
	}

	return isDestroying ? (
		<Splash />
	) : (
		<Container maxWidth={`sm`}>
			<Spacer height={theme.spacing(10)} />
			<Typography variant={`h3`} fontWeight={600} textAlign={`center`}>
				Your account
			</Typography>
			<Typography component={`h4`} variant={`large`} textAlign={`center`}>
				Here you can update all your personal, contact, security and profile related information
			</Typography>
			<Spacer height={theme.spacing(5)} />
			<Section>
				<SectionTitle title={'Personal information'} icon={'contactCard'} />
				{first_name && <SectionItem label={`First name(s)`} value={first_name} />}
				{last_name && <SectionItem label={`Last name`} value={last_name} />}
				<Spacer height={theme.spacing(1)} />
				<Button variant={`outlined`} color={`secondary`} onClick={() => navigate(`/account/update`)}>
					Update
				</Button>
			</Section>
			<Section>
				<SectionTitle title={'Contact info & password'} icon={'contactCard'} />
				{email && <SectionItem label={`Email Address`} value={email} linkTitle={`Edit`} linkUrl={`/account/update-email`} />}
				{contact_number && (
					<SectionItem
						label={`Contact number`}
						value={contact_number}
						linkTitle={`Edit`}
						linkUrl={`/account/update-contact-number`}
					/>
				)}
				<SectionItem label={`Password`} value={`**********`} linkTitle={`Update password`} linkUrl={`/account/update-password`} />
			</Section>
			<Section>
				<SectionTitle title={'Profile information'} icon={'contactCard'} />
				{gender && <SectionItem label={`Gender`} value={startCase(gender)} />}
				{dob && <SectionItem label={`Date of birth`} value={dob} />}
				{country && <SectionItem label={`Country of residence`} value={country} />}
				{country_citizenship && (
					<SectionItem
						label={`South African citizen`}
						value={startCase(String(country_citizenship === `South Africa` ? 'Yes' : 'No'))}
					/>
				)}
				{id_number && southAfrican && <SectionItem label={`South African ID`} value={id_number} />}
				{municipality && <SectionItem label={`Municipality of residence`} value={municipality} />}
				{southAfrican && country === `South Africa` && (
					<SectionItem label={`Annual income`} value={`${income} than R250 000 (ZAR)`} />
				)}
				{race && southAfrican && <SectionItem label={`Ethnicity`} value={startCase(race)} />}
				<Spacer height={theme.spacing(1)} />
				<Button variant={`outlined`} color={`secondary`} onClick={() => navigate(`/account/update`)}>
					Update
				</Button>
				<Spacer height={theme.spacing(1)} />
				<Button
					variant={`text`}
					color={`secondary`}
					isLoading={isDestroying}
					onClick={() => {
						setDialog({
							open: true,
							title: `Delete account`,
							description: `If you delete this account, your access to both Consumer Financial Education and ReadytoWork will be removed.`,
							onConfirm: deleteAccount,
							setDialog,
							confirmTitle: 'Delete',
							variant: 'error',
						})
					}}>
					Delete my account
				</Button>
			</Section>
			<Spacer height={theme.spacing(6)} />
		</Container>
	)
}

export default Component
