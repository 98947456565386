import { Box } from 'components/box/box'
import { FullModuleSummary } from 'types/cfe_assessment'

import { Container, Typography } from '@mui/material'
import { Icon } from 'components/icon'
import { ReactNode } from 'react'
import { colors } from '../../../../../../constants'

interface AssessmentHeaderProps {
	module: FullModuleSummary
	updateCurrentModuleIndex: (value: any) => any
	action?: ReactNode
}

export const AssessmentHeader: React.FC<AssessmentHeaderProps> = ({ module, updateCurrentModuleIndex, action }) => {
	return (
		<Box backgroundColor={colors.brand_graphite_8}>
			<Container maxWidth={`lg`} sx={{ backgroundColor: colors.brand_graphite_8 }}>
				<Box
					backgroundColor={colors.brand_graphite_8}
					display='flex'
					onClick={() => updateCurrentModuleIndex(null)}
					fullWidth
					sx={{
						// display: 'flex',
						alignItems: 'center',
						gap: '16px',
						padding: '16px 0',
					}}>
					<Icon name='caretLeft' width={20} height={20} />
					<Box flexGrow={1}>
						<Typography fontSize={'18px'} lineHeight={'21px'} fontWeight={700}>
							{module.module.name}
						</Typography>
					</Box>
					{action}
				</Box>
			</Container>
		</Box>
	)
}
