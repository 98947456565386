import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Splash } from '../../../components/splash'
import { useAuthService } from '../../../services/user'
import { sleep } from '../../../utils'

export default function PageLogout() {
	const { userLogout } = useAuthService()
	const navigate = useNavigate()

	useEffect(() => {
		;(async () => {
			await userLogout()
			await sleep(1000)
			navigate('/')
		})()
	}, [])

	return <Splash />
}
