import { Box, Typography, useMediaQuery } from '@mui/material'
import { Button } from 'components/button/button'
import { Spacer } from 'components/layout/spacer'
import { ReactNode } from 'react'
import { colors, sizing } from '../../../../../../constants'

interface AppCardProps {
	title: string
	children?: ReactNode
	fullWidth?: boolean
	onActionClick?: () => void
	actionLabel?: string
}

const appCardStyles = {
	card: {
		borderRadius: '4px',
		border: `1px solid ${colors.brand_graphite_13}`,
		borderLeft: '4px solid #F05A78',
		padding: '16px',
	},
}

const AppCard: React.FC<AppCardProps> = ({ title, fullWidth, children, onActionClick, actionLabel = 'Go to Learning' }) => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true

	return (
		<Box display='flex' flexDirection='column' sx={{ ...appCardStyles.card, width: fullWidth ? '100%' : null }}>
			<Box flexGrow={1}>
				<Box>
					<Typography variant={isMobile ? 'h5' : 'h4'}>{title}</Typography>
				</Box>
				<Box>{children}</Box>
			</Box>

			{onActionClick && (
				<Box display='flex' flexDirection='column' alignItems='center'>
					<Spacer height={isMobile ? sizing.themeSpacing : sizing.themeSpacing * 2} />
					<Box>
						<Button onClick={onActionClick} size='small' color='secondary' variant='contained' fullWidth={false}>
							{actionLabel}
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	)
}

export default AppCard
