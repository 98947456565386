import { Box } from '../../../box/box'
import { Spacer } from '../../../layout/spacer'
import { colors, sizing } from '../../../../constants'
import React, { useEffect, useState } from 'react'

interface PasswordStrengthBarProps {
	password: string
}

export const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({
	password,
}) => {
	const [strength, setStrength] = useState<number>(0)
	const calculateStrength = (password: string): number => {
		let score = 0

		/**
		 * Check for lowercase letters
		 */
		if (/[a-z]/.test(password)) {
			score += 1
		}

		/**
		 * Check for uppercase letters
		 */
		if (/[A-Z]/.test(password)) {
			score += 1
		}

		/**
		 * Check for numbers
		 */
		if (/[0-9]/.test(password)) {
			score += 1
		}

		/**
		 * Check for special characters
		 */
		if (/[^A-Za-z0-9]/.test(password)) {
			score += 1
		}

		/**
		 * Check for length
		 */
		if (password.length >= 12) {
			score += 1
		}

		return score
	}

	useEffect(() => {
		const strengthValue = calculateStrength(password)
		setStrength(strengthValue)
	}, [password])

	const getStrengthColor = (strength: number): string => {
		if (strength === 0) return colors.danger // Weak
		if (strength === 1) return colors.brand_care // Medium
		if (strength === 2) return colors.brand_care // Fair
		if (strength === 3) return colors.brand_success // Strong
		if (strength === 4) return colors.brand_success // Very Strong
		return colors.brand_success
	}

	return (
		<React.Fragment>
			<Spacer height={sizing.themeSpacing / 2} />
			<Box
				height={`3px`}
				marginX={`${sizing.borderRadius}px`}
				style={{
					backgroundColor: '#f0f0f0',
					borderRadius: '5px',
				}}>
				<div
					style={{
						width: `${(strength / 5) * 100}%`,
						height: '100%',
						backgroundColor: getStrengthColor(strength),
						borderRadius: '5px',
					}}></div>
			</Box>
		</React.Fragment>
	)
}
