import { IconButtonProps, IconButton as MuiIconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'

type ButtonMirrorProps = IconButtonProps & {
	disabled?: boolean
}

export const IconButton: React.FC<ButtonMirrorProps> = ({
	children,
	disabled,
	...props
}) => {
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setIsLoading(false)
	}, [])

	return (
		<MuiIconButton
			type={`button`}
			{...props}
			disabled={isLoading ?? disabled}>
			{children}
		</MuiIconButton>
	)
}
