export const MODULE_COURSE_NAME = 'Financial Literacy Course'
export const MODULE_COURSE_NAME_FOLDER = 'financial-literacy-course'
// export const MODULE_COUSE_NAME_SCORM = 'blockchain'

export interface Quiz {
	id: number
	name: string
	description?: string
	questions: Question[]
}

export interface QuizStatus {
	progress: number
}

export interface Question {
	id: number
	text: string
	options: AnswerOption[]
}

export interface AnswerOption {
	id: number
	text: string
}

export interface ModuleSummary {
	id: number
	name: string
	completion_message?: string
	module_type: 'quiz' | 'course'
	status: 'available' | 'unavailable' | 'in_progress' | 'completed'
	locked: boolean
	quizzes: number[]
	quiz_status: QuizStatus
	course_status: { [key: string]: number }
	completed_offline: boolean
}

export interface QuizMap {
	[key: number]: Quiz
}

export interface FullModuleSummary {
	module: ModuleSummary
	quizzes: QuizMap
}
