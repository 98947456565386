import { Box, Typography, useMediaQuery } from '@mui/material'
import { Button } from 'components/button/button'
import { Spacer } from 'components/layout/spacer'
import DeterminateProgress from 'components/progress/src/Component.determinate'
import { useNavigate } from 'react-router-dom'
import { MODULE_COURSE_NAME, ModuleSummary } from 'types/cfe_assessment'
import { colors, sizing } from '../../../../../../constants'

interface AppAssessmentProgressProps {
	index: number
	module: ModuleSummary
}

const AppAssessmentProgress: React.FC<AppAssessmentProgressProps> = ({ index, module }) => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const navigate = useNavigate()

	const getStatusLabel = () => {
		if (module.status === 'completed' || module.completed_offline) {
			return 'Completed'
		}

		if (module.locked) {
			return 'Not started'
		}

		switch (module.status) {
			case 'in_progress': {
				return 'In progress'
			}
			case 'available': {
				return 'Available'
			}
			default: {
				return 'Not started'
			}
		}
	}

	return (
		<Box sx={{ paddingY: `${sizing.themeSpacing}px` }}>
			<Box>
				<Typography variant={isMobile ? 'normal' : 'medium'} marginBottom='0px' fontWeight={600}>
					{index + 1}. {module.name}
				</Typography>
			</Box>
			<Box>
				<Typography variant={isMobile ? 'small' : 'normal'} color={colors.brand_graphite_82}>
					{getStatusLabel()}
				</Typography>
			</Box>
			{['in_progress', 'available'].indexOf(module.status) !== -1 &&
				module.module_type === 'course' &&
				!module.locked &&
				!module.completed_offline && (
					<Box sx={{ marginTop: `${sizing.themeSpacing}px` }}>
						<Typography variant={isMobile ? 'small' : 'normal'} color={colors.brand_graphite_82}>
							{module.course_status[MODULE_COURSE_NAME] ?? 0}% completed
						</Typography>
						<Spacer height={sizing.themeSpacing * 0.75} />
						<DeterminateProgress value={module.course_status[MODULE_COURSE_NAME] ?? 0} />
						<Spacer height={sizing.themeSpacing} />
						<Button
							size='small'
							variant='outlined'
							onClick={() => navigate(`/consumer-financial-education?module_index=${index}`)}
							color='secondary'>
							Resume Learning
						</Button>
					</Box>
				)}
		</Box>
	)
}

export default AppAssessmentProgress
