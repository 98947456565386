import { PaletteOptions, ThemeOptions, createTheme } from '@mui/material/styles'
import chroma from 'chroma-js'
import { colors, sizing } from '../../constants'

interface CustomPaletteOptions extends PaletteOptions {
	muted?: {
		main?: string
	}
	text: {
		primary?: string
		secondary?: string
		disabled?: string
	}
}

interface CustomThemeOptions extends ThemeOptions {
	palette?: CustomPaletteOptions
}

export const themeOptions: CustomThemeOptions = {
	breakpoints: {
		values: {
			xs: sizing.xs,
			sm: sizing.sm,
			md: sizing.md,
			lg: sizing.lg,
			xl: sizing.xl,
		},
	},
	palette: {
		mode: 'light',
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.secondary,
		},
		error: {
			main: colors.danger,
		},
		warning: {
			main: colors.brand_warning,
		},
		info: {
			main: colors.brand_info,
		},
		success: {
			main: colors.brand_success,
		},
		muted: {
			main: colors.muted,
		},
		divider: colors.divider,
		background: {
			default: colors.bg,
			paper: colors.bgSurface,
		},
		text: {
			primary: colors.text,
			secondary: colors.secondary,
			disabled: colors.disabled,
		},
	},
	typography: {
		fontFamily: 'Source Sans Pro',
		fontSize: sizing.bodyFontSize,
		giant: {
			fontFamily: 'Source Sans Pro',
			fontSize: 34,
			lineHeight: `51px`,
			fontWeight: 400,
		},
		mega: {
			fontFamily: 'Source Sans Pro',
			fontSize: 28,
			color: colors.text,
			lineHeight: `42px`,
			fontWeight: 400,
		},
		huge: {
			fontFamily: 'Source Sans Pro',
			fontSize: 24,
			color: colors.text,
			lineHeight: `36px`,
			fontWeight: 400,
		},
		jumbo: {
			fontFamily: 'Source Sans Pro',
			fontSize: 20,
			color: colors.text,
			lineHeight: `30px`,
			fontWeight: 400,
		},
		large: {
			fontFamily: 'Source Sans Pro',
			fontSize: 18,
			color: colors.text,
			lineHeight: `27px`,
			fontWeight: 400,
		},
		medium: {
			fontFamily: 'Source Sans Pro',
			fontSize: 16,
			color: colors.text,
			lineHeight: `24px`,
			fontWeight: 400,
		},
		normal: {
			fontFamily: 'Source Sans Pro',
			fontSize: 14,
			color: colors.text,
			lineHeight: `21px`,
		},
		small: {
			fontFamily: 'Source Sans Pro',
			fontSize: 12,
			color: colors.text,
			lineHeight: `18px`,
		},
		subtitle1: {
			fontFamily: 'Source Sans Pro',
			fontSize: sizing.bodyFontSize + 2,
			color: colors.text,
			lineHeight: `${sizing.themeSpacing * 3}px`,
			marginBottom: sizing.themeSpacing,
		},
		subtitle2: {
			fontFamily: 'Source Sans Pro',
			fontSize: sizing.bodyFontSize,
			color: colors.text,
			marginBottom: sizing.themeSpacing,
		},
		body1: {
			fontFamily: 'Source Sans Pro',
			fontSize: sizing.bodyFontSize,
			color: colors.text,
		},
		body2: {
			fontFamily: 'Source Sans Pro',
			fontSize: sizing.bodyFontSize * 0.9,
			color: colors.text,
		},
		h1: {
			fontFamily: 'Source Sans Pro',
			fontSize: 36,
			fontWeight: '600',
			marginBottom: sizing.themeSpacing * 1.5,
		},
		h2: {
			fontFamily: 'Source Sans Pro',
			fontSize: 30,
			fontWeight: '600',
			marginBottom: sizing.themeSpacing * 1.2,
		},
		h3: {
			fontFamily: 'Source Sans Pro',
			fontSize: 26,
			fontWeight: '600',
			marginBottom: sizing.themeSpacing,
		},
		h4: {
			fontFamily: 'Source Sans Pro',
			fontSize: 24,
			fontWeight: '600',
			marginBottom: sizing.themeSpacing,
		},
		h5: {
			fontFamily: 'Source Sans Pro',
			fontSize: 18,
			fontWeight: '600',
			marginBottom: sizing.themeSpacing * 0.8,
		},
		h6: {
			fontFamily: 'Source Sans Pro',
			fontSize: 14,
			fontWeight: '600',
			marginBottom: sizing.themeSpacing * 1.7,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 60,
					fontSize: sizing.bodyFontSize * 1.1,
					paddingLeft: 36,
					paddingRight: 36,
					fontFamily: 'Source Sans Pro',
					fontWeight: 600,
					textTransform: 'none',
					whiteSpace: 'nowrap',
					boxShadow: 'none',
					'&:hover': {
						boxShadow: 'none',
					},
					'&.MuiButton-sizeSmall': {
						borderRadius: sizing.buttonBorderRadius,
						padding: '0 8px',
						fontSize: 13,
						height: Math.round(sizing.inputHeight * 0.9),
					},
					'&.MuiButton-sizeMedium': {
						borderRadius: sizing.buttonBorderRadius,
						padding: '0 24px',
						fontSize: Math.round(sizing.bodyFontSize * 1.15),
						height: sizing.inputHeight,
					},
					'&.MuiButton-sizeLarge': {
						borderRadius: sizing.buttonBorderRadius,
						height: Math.round(sizing.inputHeight * 1.3),
					},
					'&.MuiButtonGroup-grouped': {
						borderColor: colors.divider,
						'&:not(:first-child):not(:last-child)': {
							borderRadius: 0,
						},
						'&:first-child': {
							borderTopRightRadius: 0,
							borderBottomRightRadius: 0,
						},
						'&:last-child': {
							borderTopLeftRadius: 0,
							borderBottomLeftRadius: 0,
						},
					},
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					'& .MuiLoadingButton-loadingIndicator': {
						color: colors.primary,
					},
				},
			},
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: `${sizing.themeSpacing} auto`,
					width: `100%`,
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
					color: colors.brand_hope,
					fontSize: sizing.bodyFontSize,
					'&:hover': {
						color: colors.primary,
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					color: colors.text,
					fontSize: 24,
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				color: colors.text,
			},
			styleOverrides: {
				root: {
					color: colors.text,
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					backgroundColor: colors.bgSurface,
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				...{
					root: {
						borderRadius: sizing.borderRadius,
						'.MuiOutlinedInput-notchedOutline': {
							color: colors.text,
							borderColor: colors.muted,
							borderWidth: sizing.borderWidth,
							boxShadow: 'none',
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: colors.brand_care,
							color: colors.text,
							borderWidth: sizing.borderWidth,
							boxShadow: 'none',
						},
						textTransform: 'none',
						borderWidth: sizing.borderWidth,
						boxShadow: 'none',
						marginTop: '4px',
						color: colors.text,
						'.MuiSelect-icon': {
							color: colors.muted,
						},
					},
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginLeft: 0,
					color: colors.textHelper,
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: 'primary.main',
					},
					'&.MuiCheckbox-colorPrimary': {
						'&:hover': {
							color: 'primary.dark',
						},
					},
					'&.MuiOutlinedInput-root': {
						'&:hover fieldset': {
							borderColor: 'grey',
						},
						'&.Mui-focused fieldset': {
							borderColor: 'primary.main',
						},
					},
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: colors.brand_care, // Color when checked
						'&.Mui-disabled': {
							opacity: 0.25,
							color: colors.muted,
						},
					},
					'&.MuiOutlinedInput-root': {
						'&:hover fieldset': {
							borderColor: colors.brand_care, // Outline color on hover
						},
						'&.Mui-focused fieldset': {
							borderColor: colors.brand_care, // Outline color when focused
						},
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					height: sizing.inputHeight,
					'&.Mui-disabled': {
						opacity: 0.625,
						color: colors.disabled,
						// backgroundColor: colors.muted,
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					borderWidth: sizing.borderWidth,
					boxShadow: 'none',
					marginTop: '4px',
					color: colors.text,
					borderRadius: sizing.borderRadius,
					'& .MuiInputBase-root': {
						borderWidth: sizing.borderWidth,
						borderRadius: sizing.borderRadius,
						'&:disabled': {
							color: colors.muted,
						},
					},
					'& .MuiOutlinedInput-input': {
						// height: '48px',
					},
					'& .MuiOutlinedInput-disabled': {
						color: colors.muted,
					},
					'& .MuiOutlinedInput-root': {
						borderColor: colors.brand_care,
						color: colors.text,
						borderWidth: sizing.borderWidth,
						boxShadow: 'none',
						'& fieldset': {
							borderColor: colors.muted,
							borderWidth: sizing.borderWidth,
							boxShadow: 'none',
						},
						'&:hover fieldset': {
							borderColor: colors.brand_care,
							color: colors.text,
							borderWidth: sizing.borderWidth,
							boxShadow: 'none',
						},
						'&.Mui-focused fieldset': {
							color: colors.text,
							borderColor: colors.text,
							borderWidth: sizing.borderWidth,
							boxShadow: 'none',
						},
					},
					'& .MuiInputLabel-animated': {
						transition: 'none',
					},
					'& .MuiInputLabel-animated.MuiInputLabel-shrink': {
						transform: 'none',
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: colors.muted,
					'&.Mui-focused': {
						color: colors.text,
					},
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: 46,
					height: 27,
					padding: 0,
					margin: 8,
				},
				switchBase: {
					padding: 1,
					'&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
						transform: 'translateX(16px)',
						color: '#fff',
						'& + $track': {
							opacity: 1,
							border: 'none',
						},
					},
				},
				thumb: {
					width: 24,
					height: 24,
				},
				track: {
					borderRadius: 13,
					border: '1px solid #bdbdbd',
					backgroundColor: '#fafafa',
					opacity: 1,
					transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					fontSize: '24px',
					padding: `${sizing.themeSpacing}px`,
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: sizing.borderRadius,
					padding: `${sizing.themeSpacing}px`,
					margin: `${sizing.themeSpacing}px`,
					backgroundImage: 'none',
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					color: colors.text,
					border: 'none',
					borderTop: 'none',
					borderBottom: 'none',
				},
			},
		},
		MuiStack: {
			defaultProps: {
				useFlexGap: true,
			},
		},
		MuiSnackbar: {
			defaultProps: {
				className: `-cdp-ce-ui-snackbar`,
			},
			styleOverrides: {
				root: {
					color: `white`,
					padding: 0,
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					borderRadius: 12,
					opacity: 1,
				},
				message: {
					lineHeight: '21px',
				},
				filledSuccess: {
					backgroundColor: colors.brand_success,
					color: `${colors.text} !important`,
				},
				standardSuccess: {
					backgroundColor: colors.brand_success,
					color: `${colors.text} !important`,
				},
				filledError: {
					backgroundColor: colors.danger,
					color: `${colors.text} !important`,
				},
				standardError: {
					backgroundColor: colors.danger,
					color: `${colors.text} !important`,
				},
				filledWarning: {
					backgroundColor: colors.brand_warning,
					color: `${colors.primary} !important`,
				},
				standardWarning: {
					backgroundColor: colors.brand_warning,
					color: `${colors.primary} !important`,
				},
				filledInfo: {
					backgroundColor: colors.muted,
					color: `${colors.primary} !important`,
				},
				standardInfo: {
					backgroundColor: colors.muted,
					color: `${colors.primary} !important`,
				},
				outlinedInfo: {
					backgroundColor: chroma(colors.brand_info).brighten(3.3).hex(),
					border: `1px solid ${colors.brand_info}`,
					color: `${colors.brand_info} !important`,
				},
				outlinedSuccess: {
					backgroundColor: '#F2FCE9',
					border: `1px solid ${colors.brand_success}`,
					color: `${colors.brand_success} !important`,
				},
				outlinedWarning: {
					backgroundColor: chroma(colors.brand_warning).brighten(4.5).hex(),
					border: `1px solid ${colors.brand_warning}`,
					color: `${colors.brand_warning} !important`,
				},
				outlinedError: {
					backgroundColor: chroma(colors.danger).brighten(5).hex(),
					border: `1px solid ${colors.danger}`,
					color: `${colors.danger} !important`,
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					transition: 'none',
					backgroundColor: colors.bgSurface,
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: colors.bgSurface,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						color: colors.primary,
					},
					fontWeight: `bold`,
					fontSize: sizing.bodyFontSize,
					textTransform: 'none',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: colors.bgSurface,
					backgroundImage: 'none',
					borderRadius: sizing.borderRadius * 2,
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					backgroundColor: `transparent`,
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					padding: 0,
					backgroundColor: `transparent`,
				},
			},
		},
		MuiListSubheader: {
			styleOverrides: {
				root: {
					backgroundColor: `transparent`,
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: sizing.borderRadius,
				},
			},
		},
	},
	spacing: sizing.themeSpacing,
	direction: `ltr`,
	shape: {
		borderRadius: sizing.borderRadius,
	},
}

export const theme = createTheme(themeOptions)

declare module '@mui/material/styles' {
	// eslint-disable-next-line no-unused-vars
	interface TypographyVariants {
		giant: React.CSSProperties
		mega: React.CSSProperties
		huge: React.CSSProperties
		jumbo: React.CSSProperties
		large: React.CSSProperties
		medium: React.CSSProperties
		normal: React.CSSProperties
		small: React.CSSProperties
	}
	// eslint-disable-next-line no-unused-vars
	interface TypographyVariantsOptions {
		giant?: React.CSSProperties
		mega?: React.CSSProperties
		huge?: React.CSSProperties
		jumbo?: React.CSSProperties
		large?: React.CSSProperties
		medium?: React.CSSProperties
		normal?: React.CSSProperties
		small?: React.CSSProperties
	}
}

declare module '@mui/material/Typography' {
	// eslint-disable-next-line no-unused-vars
	interface TypographyPropsVariantOverrides {
		giant: true
		mega: true
		huge: true
		jumbo: true
		large: true
		medium: true
		normal: true
		small: true
	}
}
