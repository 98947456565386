import { Container } from '@mui/material'
import { Box } from 'components/box/box'
import { Support } from 'components/support'
import { FormUpdateEmail } from '../../components/form-controlled/account/email/form'

export default function PageAccountUpdateEmail() {
	return (
		<Box display='flex' flexDirection='column' minHeight='100%'>
			<Box flexGrow={1}>
				<Container maxWidth={`xs`}>
					<FormUpdateEmail />
				</Container>
			</Box>
			<Support />
		</Box>
	)
}
