import { Container, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from '../error-boundary'
import Layout from './layout'

function RoutesErrorBoundary() {
	return (
		<Layout>
			<ErrorBoundary>
				<Container maxWidth={`sm`}>
					<Typography variant={`h1`}>Error loading page</Typography>
				</Container>
			</ErrorBoundary>
			<Outlet />
		</Layout>
	)
}

export default RoutesErrorBoundary
